import React from "react";

function Loading(props) {
  return (
    <div
      style={
        props.loading
          ? {
              width: "calc(100%)",
              height: "calc(100%)",
              paddingTop: "50px",
              paddingLeft: "30px",
              top: "-50px",
              left: "-30px",
              backgroundColor: "rgba(1,1,1)",
              opacity: "0.4",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }
          : {
              display: "none",
            }
      }
    >
      <div className="spinner" />
    </div>
  );
}

export default Loading;
