import React, { useEffect, useState } from "react";
import style from "../../css/search.module.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
function Selectbox(props) {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };
  const [selectlist, setSelectlist] = useState(props.selectlist);
  useEffect(() => {
    setSelectlist(props.selectlist);
  }, [props.selectlist]);

  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className={style.selectbox}>
      <FormControl sx={{ minWidth: props.width }} size="small">
        <Select
          value={value}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          sx={{ fontSize: 14, height: 35 }}
        >
          {selectlist.length > 0
            ? selectlist.map((value, index) => (
                <MenuItem value={value.key} key={index} sx={{ fontSize: 14 }}>
                  {value.label}
                </MenuItem>
              ))
            : ""}
        </Select>
      </FormControl>
    </div>
  );
}

export default Selectbox;
