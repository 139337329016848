import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "../../css/monitoring.module.css";
import axios from "axios";
import Selectbox from "./../search/selectbox";
import Searchbtn from "./../search/searchbtn";
import Inputtext from "./../search/inputtext";

function Vctable(props) {
  const [tableList, setTableList] = useState([""]);

  const check_hold = (vcId) => {
    axios.put("/user/monitoring/vcHold/" + vcId).then((res) => {
      gettableList();
    });
  };
  const [counts, setCounts] = useState({
    ZRtotal: "",
    ZRoffline: "",
    ZRmeeting: "",
    CRCtotal: "",
    CRCoffline: "",
    CRCmeeting: "",
  });

  const searchtypelist = [
    { key: "deviceIp", label: "Device IP" },
    { key: "displayNm", label: "Display Name" },
    { key: "deviceNm", label: "Device Name" },
    { key: "deviceType", label: "Device Type" },
  ];
  const [input, setInput] = useState({
    keyword1: "",
    searchOption1: "deviceIp",
  });

  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    searchOption1: "deviceIp",
  });

  const search = () => {
    setSearchInfo(input);
    gettableList();
  };

  const gettableList = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        searchOption1: searchInfo.searchOption1,
      },
    };

    axios.get("/user/monitoring/getVcStatus", config).then((res) => {
      if (res.status === 200) {
        setTableList(res.data.data);
        let ZRtotal = res.data.data.filter((item) => item.deviceType === "ZR");
        let ZRoffline = ZRtotal.filter((item) => item.status === "Offline");
        let ZRmeeting = ZRtotal.filter((item) => item.status === "InMeeting");
        let CRCtotal = res.data.data.filter(
          (item) => item.deviceType === "CRC"
        );
        let CRCoffline = CRCtotal.filter((item) => item.status === "offline");
        let CRCmeeting = CRCtotal.filter((item) => item.status === "InMeeting");
        setCounts({
          ZRtotal: ZRtotal.length,
          ZRoffline: ZRoffline.length,
          ZRmeeting: ZRmeeting.length,
          CRCtotal: CRCtotal.length,
          CRCoffline: CRCoffline.length,
          CRCmeeting: CRCmeeting.length,
        });
      }
    });
  }, [searchInfo]);
  useEffect(() => {
    gettableList();
  }, [gettableList]);

  useEffect(() => {
    gettableList();
  }, [gettableList, props.refresh]);

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [tableList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  return (
    <div className={style.statusBoard}>
      <div className={style.statusBoard_header_vc}>
        <div className={style.devicestatus}>
          <div>StatusBoard</div>
          <div className={style.statusBoard_header_vc_info}>
            <div>Zoom Room</div>
            <div>
              <font>{counts.ZRtotal}</font>
              <font>rooms</font>
              <font>|</font>
              <font>{counts.ZRoffline}</font>
              <font>offline</font>
              <font>|</font>
              <font>{counts.ZRmeeting}</font>
              <font>in Meeting</font>
            </div>
          </div>
          <div className={style.statusBoard_header_vc_info}>
            <div>CRC</div>
            <div>
              <font>{counts.CRCtotal}</font>
              <font>rooms</font>
              <font>|</font>
              <font>{counts.CRCoffline}</font>
              <font>offline</font>
              <font>|</font>
              <font>{counts.CRCmeeting}</font>
              <font>in Meeting</font>
            </div>
          </div>
        </div>
        <div className="flex" style={{ gap: "65px", height: "35px" }}>
          <div
            style={{ display: "flex", margin: "0px" }}
            className={style.vcsearch}
          >
            <Selectbox
              width={110}
              selectlist={searchtypelist}
              placeHolder=""
              value={input.searchOption1}
              onChange={(val) => setInput({ ...input, searchOption1: val })}
            />
            <Inputtext
              value={input.keyword1}
              onChange={(value) => setInput({ ...input, keyword1: value })}
            />
            <Searchbtn search={() => search()} />
          </div>
        </div>
      </div>
      <div className={style.Statusboard_body_vc}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.vc_tr}>
              <div></div>
              <div>Device IP</div>
              <div>Display Name</div>
              <div>Device Name</div>
              <div>Location</div>
              <div>Version</div>
              <div>Device Type</div>
              <div>status</div>
            </div>
          </div>
          <div className={style.table_body_wrapper}>
            <div className={style.table_body}>
              {tableList.map((table, index) => (
                <div
                  className={
                    table.status === "InMeeting"
                      ? `${style.Meeting_vc + " " + style.vc_tr}`
                      : table.status === "Error"
                      ? `${style.Error_vc + " " + style.vc_tr}`
                      : table.status === "Warning"
                      ? `${style.Warning_vc + " " + style.vc_tr}`
                      : table.status === "Available"
                      ? `${style.Online_vc + " " + style.vc_tr}`
                      : table.status === "Offline"
                      ? `${style.Offline_vc + " " + style.vc_tr}`
                      : `${style.Offline_vc + " " + style.vc_tr}`
                  }
                  key={index}
                  data-index={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                >
                  <div className="cursor" onClick={() => check_hold(table.id)}>
                    {table.hold === "Y" ? (
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/monitoring/holdy.png"
                        }
                      />
                    ) : (
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/monitoring/holdn.png"
                        }
                      />
                    )}
                  </div>
                  <div>{table.ip}</div>
                  <div>{table.displayNm}</div>
                  <div>{table.deviceNm}</div>
                  <div>{table.location}</div>
                  <div>{table.version}</div>
                  <div>{table.deviceType}</div>
                  <div>{table.status}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {tableList.length}
      </div>
    </div>
  );
}

export default Vctable;
