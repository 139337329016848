import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/monitoring.module.css";
import Issuecard from "./issuecard";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
function Issuelist(props) {
  const [issueList, setIssueList] = useState([]);
  const location = useLocation();
  const payload = useSelector((state) => state.user);

  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);

  const getissueList = useCallback(
    (data) => {
      let config = {
        params: {
          selectId: data,
          limit: limit,
        },
      };
      axios.get("/user/monitoring/getIssue", config).then((res) => {
        if (res.status === 200) {
          location.pathname.split("/")[3] === "vcmonitoring"
            ? setIssueList([])
            : setIssueList(res.data.data);
          props.setLoad(false);
        }
      });
    },
    [limit, location.pathname]
  );
  const getIssueCount = useCallback(
    (data) => {
      let config = {
        params: {
          groupId: data,
        },
      };
      axios.get("/user/monitoring/getIssueCounts", config).then((res) => {
        if (res.status === 200) {
          location.pathname.split("/")[3] === "vcmonitoring"
            ? setTotal(0)
            : setTotal(res.data.data.total);
        }
      });
    },
    [location.pathname]
  );

  useEffect(() => {
    if (location.pathname.split("/")[3] === "vipmonitoring") {
      getissueList("Y");
      getIssueCount("Y");
    } else {
      getissueList(null);
      getIssueCount(null);
    }
  }, [location.pathname, getissueList, props.refresh]);

  const { ref: ref1, inView: inview1 } = useInView({
    threshold: 1.0, // 1.0 means that the target element is fully visible
  });
  useEffect(() => {
    if (inview1) {
      if (total > limit) {
        let limitData = limit + 100;
        setLimit(limitData);
        props.setLoad(true);
      }
    }
  }, [inview1]);

  const close = (id, errortype) => {
    const data = {
      id: id,
      selectId: payload.name,
      selectUserId: payload.email,
    };

    if (errortype === "data_center" || errortype === "location") {
      axios.put("/user/monitoring/region", data, "").then((res) => {
        props.setrefresh(!props.refresh);
      });
    } else {
      axios.put("/user/monitoring/issue", data, "").then((res) => {
        props.setrefresh(!props.refresh);
      });
    }
  };

  useEffect(() => {
    if (issueList.length > 0) {
      props.issuestatus(true);
    } else {
      props.issuestatus(false);
    }
  }, [issueList, props.issuestatus]);

  const closeAll = () => {
    const data = {
      id: null,
      selectId: payload.name,
      selectUserId: payload.email,
    };
    axios.put("/user/monitoring/updateAll", data, "").then((res) => {
      props.setrefresh(!props.refresh);
    });
  };

  return (
    <div
      className={issueList.length > 0 ? style.issuelist : style.issuelist_none}
    >
      <div className={style.issue_header}>
        <label>Issue Occured</label>
        <label>|</label>
        <label>Issue Total :</label>
        <label>{total}</label>
        <div
          onClick={() => {
            closeAll();
          }}
        >
          Confirm All
        </div>
      </div>
      <div className={style.issue_body}>
        {issueList.map((issue, index) => (
          <div key={index} style={{ width: "290px" }}>
            <Issuecard
              data={issue}
              close={(number, type) => close(number, type)}
              index={index}
            />
          </div>
        ))}
        <div ref={ref1} style={{ height: "10px", width: "10" }} />
      </div>
    </div>
  );
}

export default Issuelist;
