import React from "react";
import style from "../../css/adminComp.module.css";

function DeleteBtn({ onclickEvent }) {
  return (
    <button className={`${style.btn} ${style.delete}`} onClick={onclickEvent}>
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/delete_icon.png"}
      />
      <div>Delete</div>
    </button>
  );
}

export default DeleteBtn;
