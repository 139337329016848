import React, { useCallback, useEffect, useState } from "react";
import style from "../css/report.module.css";
import Selectbox from "../components/search/selectbox";
import Datepicker from "../components/search/datepicker";
import Searchbtn from "../components/search/searchbtn";
import Inputtext from "../components/search/inputtext";
import Excelbtn from "../components/search/excelbtn";
import Issuetable from "../components/table/issuetable";
import { Pagination, Stack } from "@mui/material";
import axios from "axios";

function Issuereport() {
  const searchtypelist = [
    { key: "nickName", label: "Nick Name" },
    { key: "email", label: "Email" },
    { key: "meetingId", label: "Meeting ID" },
  ];

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const getWeekBeforeToday = () => {
    const today = new Date();
    const weekBefore = new Date();
    weekBefore.setDate(today.getDate() - 7);

    return weekBefore;
  };

  const [select, setselect] = useState("nickName");
  const [keyword, sestKeyword] = useState("");
  const [startdate, setStartdate] = useState(
    getFormattedDate(getWeekBeforeToday())
  );
  const [enddate, setEnddate] = useState(getFormattedDate(new Date()));

  const [run, setrun] = useState(true);
  const [count, setcount] = useState(0);

  const search = () => {
    setSearchInfo({
      keyword1: keyword,
      searchOption1: select,
      startTime: startdate,
      endTime: enddate,
    });
    setPage(1);
    setrun(true);
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setrun(true);
  };

  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    searchOption1: "",
    startTime: getFormattedDate(getWeekBeforeToday()),
    endTime: getFormattedDate(new Date()),
  });

  const [issueList, setIssueList] = useState([]);
  const getissueList = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        searchOption1: searchInfo.searchOption1,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 20,
        limit: 20,
      },
    };
    setIssueList([]);
    axios.get("/admin/report/issue", config).then((res) => {
      if (res.status === 200) {
        setIssueList(res.data.data);
        setrun(false);
      }
    });
  }, [page, searchInfo]);

  const getissueListCount = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        searchOption1: searchInfo.searchOption1,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 20,
        limit: 20,
      },
    };
    setcount(0);
    axios.get("/admin/report/issue/counts", config).then((res) => {
      if (res.status === 200) {
        setcount(res.data.data.pages);
      }
    });
  }, [page, searchInfo]);

  useEffect(() => {
    if (run) {
      getissueList();
      getissueListCount();
    }
  }, [getissueList, run, getissueListCount]);

  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };
    const data = {
      keyWord1: searchInfo.keyword1,
      searchOption1: searchInfo.searchOption1,
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
    };

    let url = "/admin/report/issue/excel";

    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      let startTime =
        searchInfo.startTime === null ? "Entire Period" : searchInfo.startTime;
      let endTime =
        searchInfo.endTime === null ? "Entire Period" : searchInfo.endTime;

      link.setAttribute(
        "download",
        "Issue_Report(" + startTime + " ~ " + endTime + ").xlsx"
      );

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={style.report_wrapper}>
      <section className={style.report_search}>
        <div style={{ display: "flex" }}>
          <div className={style.report_search_datebox}>
            <font>Meeting Time :</font>
            <Datepicker
              value={startdate}
              onChange={(value) => setStartdate(value)}
            />
            <font> - </font>
            <Datepicker
              value={enddate}
              onChange={(value) => setEnddate(value)}
            />
          </div>

          <Selectbox
            width={134}
            selectlist={searchtypelist}
            placeHolder=""
            value={select}
            onChange={(val) => setselect(val)}
          />
          <Inputtext value={keyword} onChange={(value) => sestKeyword(value)} />
          <Searchbtn search={() => search()} />
        </div>
        <div onClick={() => excelDownload()}>
          <Excelbtn />
        </div>
      </section>
      <section>
        <Issuetable tablelist={issueList} />
        <Stack spacing={1} style={{ display: "flex" }}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            boundaryCount={4}
          />
        </Stack>
      </section>
    </div>
  );
}

export default Issuereport;
