import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/template.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import OktaAuth from "@okta/okta-auth-js";
import oktaConfig from "../common/oktaConfig";
import axios from "axios";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [init, setInit] = useState(true);
  const [user, setUser] = useState({
    email: "",
  });

  const getUserInfo = useCallback(() => {
    oktaAuth
      .getUser()
      .then((res) => {
        setUser(res);
      })
      .catch(() => {
        navigate("/");
      });
  }, [navigate]);

  useEffect(() => {
    if (init) {
      getUserInfo();
      setInit(false);
    }
  }, [getUserInfo, init]);

  const title = () => {
    const title =
      location.pathname.split("/")[3] === "monitoring"
        ? "Monitoring"
        : location.pathname.split("/")[3] === "vipmonitoring"
        ? "VIP monitoring"
        : location.pathname.split("/")[3] === "vcmonitoring"
        ? "VC monitoring"
        : location.pathname.split("/")[2] === "meetings"
        ? location.pathname.split("/")[3] === "meetingdetail"
          ? "Meeting Details"
          : location.pathname.split("/")[3] === "participantdetail"
          ? "Participant Detail"
          : "Meetings"
        : location.pathname.split("/")[2] === "participants"
        ? "Participants"
        : location.pathname.split("/")[2] === "qualityreport"
        ? "Quality report"
        : location.pathname.split("/")[2] === "issuereport"
        ? "Issue report"
        : location.pathname.split("/")[2] === "meetingdetail"
        ? "Meeting Details"
        : location.pathname.substring(1) === "monitoringaccount"
        ? "Monitoring Account"
        : location.pathname.substring(1) === "group"
        ? "Group"
        : location.pathname.substring(1) === "thresholdsetting"
        ? "Threshold Setting"
        : location.pathname.substring(1) === "slacksetting"
        ? "Slack Setting"
        : // : location.pathname.substring(1) === "slackMessage"
        // ? "Slack Message Template"
        location.pathname.substring(1) === "internalip"
        ? "Internal IP"
        : location.pathname.substring(1) === "loginlog"
        ? "Login Log"
        : location.pathname.substring(1) === "activitylog"
        ? "Activity Log"
        : location.pathname.substring(1) === "dataverify"
        ? "Data Verify"
        : location.pathname.substring(1) === "monthlyreport"
        ? "Monthly Report"
        : "";
    return title;
  };

  const signOutHandler = () => {
    oktaAuth.signOut();
  };

  return (
    <div className={style.header_wrapper}>
      <font>{title()}</font>
      <div className={style.header_info}>
        <div>
          <img
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/header_info.png"}
          />
        </div>
        <font>{user.email}</font>
        <div />
        <div className="cursor" onClick={() => signOutHandler()}>
          <img
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/logout.png"}
          />
        </div>

        <font className="cursor" onClick={() => signOutHandler()}>
          Logout
        </font>
      </div>
    </div>
  );
}

export default Header;
