import { Modal, message } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import tableStyle from "../../css/admin/vcDeviceTable.module.css";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";

function VcDevicePopup({ isOpen, close, registeredList, user }) {
  const [deviceList, setDeviceList] = useState([]);
  const url = "/admin/monitoringaccount/vcdevice";
  const header = [
    { title: "" },
    { title: "Display Name" },
    { title: "IP" },
    { title: "Type" },
    // { title: "Location" },
    { title: "Version" },
  ];
  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = useCallback(
    (type, msg) => {
      messageApi.open({
        type: type,
        content: msg,
      });
    },
    [messageApi]
  );

  const getUnregisteredVcList = useCallback(
    (vcList) => {
      // vc 장비는 ZR, CRC로 나뉨. 등록여부 필터링
      const registeredVcList = registeredList.filter(
        (reg) => reg.accountType === "ZR" || reg.accountType === "CRC"
      );

      // vc type이 CRC면 puserId가 수집되지 않아 비교를 ip와 해서 필터링
      const listWithoutRegistered = vcList.filter((data) => {
        const isRegistered = !registeredVcList.some(
          (registered) => registered.accountIp === data.deviceIp
          // ||
          // registered.paritcipantUserId === data.paritcipantUserId
        );
        // console.log(
        //   `Checking user: ${JSON.stringify(
        //     data
        //   )} - Is Registered: ${isRegistered}`
        // );
        return isRegistered;
      });
      return listWithoutRegistered;
    },
    [registeredList]
  );

  const getDeviceList = useCallback(() => {
    axios
      .get(url)
      .then((res) => {
        setDeviceList(getUnregisteredVcList(res.data.data));
      })
      .catch((err) => {
        alertMsg("error", "Failed to load VC Device List");
      });
    // .finally(() => setIsZoomListLoading(false));
  }, [alertMsg, getUnregisteredVcList]);

  useEffect(() => {
    if (isOpen) {
      getDeviceList();
    }
  }, [getDeviceList, isOpen]);

  const registerHandler = () => {
    if (checkedItems.length > 0) {
      let data = deviceList.filter((d) => checkedItems.includes(d.id));
      data = data.map((item) => ({
        ...item,
        createEmail: user.createEmail,
      }));
      axios
        .post(url, data)
        .then((res) => {
          if (res.status === 201) {
            alertMsg("success", "Device Registered Successfully");
            closeHandler();
          }
        })
        .catch((err) => {
          alertMsg("error", "Device Register Failed");
        });
      // .finally(() => setIsRegisterLoading(false));
    } else {
      messageApi.warning("No devices selected");
    }
  };

  const closeHandler = () => {
    setCheckedItems([]);
    close();
  };

  /** CHECKBOX CONTROL */
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let newCheckedItems;
    if (checked) {
      newCheckedItems = [...checkedItems, Number(value)];
    } else {
      newCheckedItems = checkedItems.filter((item) => item !== Number(value));
    }
    setCheckedItems(newCheckedItems);
  };

  return (
    <Modal
      // className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={800}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div className={comp.modal_title}>VC Device Registration</div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section
        className={comp.modal_content}
        style={{ width: "calc(100% - 10px)" }}
      >
        <div className={tableStyle.statusBoard}>
          <div className={tableStyle.Statusboard_body}>
            <div className={tableStyle.table}>
              <div className={tableStyle.table_head}>
                <div className={tableStyle.vcDevicetable_tr}>
                  {header.map((table, index) => (
                    <div key={index}> {table.title} </div>
                  ))}
                </div>
              </div>
              <div className={tableStyle.table_body}>
                {deviceList.map((device, index) => (
                  <div
                    className={tableStyle.vcDevicetable_tr}
                    key={index}
                    data-index={index}
                    // ref={(el) => (rowRefs.current[index] = el)}
                  >
                    <div>
                      <input
                        type="checkbox"
                        value={device.id}
                        checked={checkedItems.includes(device.id)}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div title={device.displayNm}>{device.displayNm}</div>
                    <div title={device.deviceIp}>{device.deviceIp}</div>
                    <div title={device.deviceType}>{device.deviceType}</div>
                    {/* <div title={device.location}>{device.location}</div> */}
                    <div title={device.version}>{device.version}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className={tableStyle.statusBoard_footer}>
            Status :{" "}
            <font>
              {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
            </font>{" "}
            of {slackList.length}
          </div> */}
        </div>
      </section>
      <section
        className={comp.modal_btnArea}
        style={{ justifyContent: "center", gap: "15px" }}
      >
        <button
          className={`${comp.modal_btn} ${comp.blueBtn}`}
          onClick={registerHandler}
        >
          Register
        </button>
        <button
          className={`${comp.modal_btn} ${comp.whiteBtn}`}
          onClick={closeHandler}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
}

export default VcDevicePopup;
