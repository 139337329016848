import { useState } from "react";
import { Cookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import ActivityLog from "../../pages/admin/activityLog";
import Group from "../../pages/admin/group";
import IpSetting from "../../pages/admin/ipSetting";
import LoginLog from "../../pages/admin/loginLog";
import MonitoringAccount from "../../pages/admin/monitoringAccount";
import SlackSetting from "../../pages/admin/slackSetting";
import ThresholdSetting from "../../pages/admin/thresholdSetting";
import Issuereport from "../../pages/issuereport";
import Meetingdetail from "../../pages/meeting/meetingdetail";
import Meetings from "../../pages/meeting/meetings";
import Participantdetail from "../../pages/meeting/participantdetail";
import Participants from "../../pages/meeting/participants";
import Monitoring from "../../pages/monitoring/monitoring";
import Qualityreport from "../../pages/qualityreport";
import Vcmonitoring from "./../../pages/monitoring/vcmonitoring";
import Vipmonitoring from "./../../pages/monitoring/vipmonitoring";
import Container from "./container";
import Header from "./header";
import Menu from "./menu";
import Wrapper from "./wrapper";
import axios from "axios";
import DataVerify from "../../pages/admin/dataVerify";
import MonthlyReport from "../../pages/admin/monthlyReport";
import oktaConfig from "../common/oktaConfig";
import RouteDetector from "../common/routeDetector";
import { OktaAuth } from "@okta/okta-auth-js";
import Alert from "../common/alert";
import ErrorBoundary from "../app/errorBoundary";
import NotFound from "../common/notFound";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function App() {
  const cookies = new Cookies();
  const [isOpen, setIsOpen] = useState(!cookies.get("menu"));
  const setOpen = (value) => {
    const cookies = new Cookies();
    setIsOpen(value);
    cookies.set("menu", !value, {
      path: "/",
      secure: true,
      maxAge: 3000,
    });
  };

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  axios.defaults.url = serverUrl;
  axios.defaults.baseURL = serverUrl;
  axios.defaults.withCredentials = false;

  //axios request
  axios.interceptors.request.use(
    async (config) => {
      if (config.params == null) {
        config.params = {};
      }
      config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  //axios response
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      const response = err.response;
      if (response !== undefined) {
        const status = response.status;

        if (status === 400) {
        } else if (status === 401) {
          window.location.href = "/";
        } else if (status === 404) {
          throw new Error("알 수 없는 요청입니다.");
        }
      }
      return Promise.reject(err);
    }
  );

  return (
    <div className="container">
      <Alert />
      <div className="app-cotainer">
        <Menu isopen={isOpen} openbtn={(value) => setOpen(value)} />
        <Wrapper>
          <Header />
          <Container>
            <div className={isOpen ? "container_body" : "container_body_c"}>
              <RouteDetector>
                <Routes>
                  <Route path="/monitoring/:id" element={<Monitoring />} />
                  {/* <Route path="/vipmonitoring" element={<Vipmonitoring />} />
                  <Route path="/vcmonitoring" element={<Vcmonitoring />} /> */}
                  <Route path="/qualityreport" element={<Qualityreport />} />
                  <Route path="/issuereport" element={<Issuereport />} />
                  <Route path="/participants/*" element={<Participants />} />
                  <Route path="/meetings/*" element={<Meetings />} />
                  <Route
                    path="/meetings/meetingdetail/*"
                    element={<Meetingdetail />}
                  />
                  <Route
                    path="/meetings/participantdetail/*"
                    element={<Participantdetail />}
                  />
                  <Route
                    path="/monitoringAccount"
                    element={<MonitoringAccount />}
                  ></Route>
                  <Route path="/group" element={<Group />}></Route>
                  <Route
                    path="/thresholdSetting"
                    element={<ThresholdSetting />}
                  ></Route>
                  <Route
                    path="/slackSetting"
                    element={<SlackSetting />}
                  ></Route>
                  {/* <Route
                    path="/slackMessage"
                    element={<SlackMessage />}
                  ></Route> */}
                  <Route path="/ipSetting" element={<IpSetting />}></Route>
                  <Route path="/loginLog" element={<LoginLog />}></Route>
                  <Route path="/activityLog" element={<ActivityLog />}></Route>
                  <Route path="/dataverify" element={<DataVerify />}></Route>
                  <Route
                    path="/monthlyreport"
                    element={<MonthlyReport />}
                  ></Route>
                  <Route Component={ErrorBoundary} />
                  <Route path="/error" element={<NotFound />} />
                  <Route
                    path={process.env.REACT_APP_CLIENT_URL + "*"}
                    element={<NotFound />}
                  />
                </Routes>
              </RouteDetector>
            </div>
          </Container>
        </Wrapper>
      </div>
    </div>
  );
}

export default App;
