import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/template/login";
import App from "./components/template/app";
import "./css/main.css";
import "./css/common.css";
import "./font/font.css";
import { LoginCallback, Security } from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";
import oktaConfig from "./components/common/oktaConfig";
import NotFound from "./components/common/notFound";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function Root() {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // window.location.href = toRelativeUrl(originalUri || "", window.location.href);
  };
  const customAuthHandler = () => {
    window.location.href = "/";
  };
  return (
    <div className="container">
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path={process.env.REACT_APP_CLIENT_URL}
              element={<Login />}
            />
            <Route
              path={process.env.REACT_APP_CLIENT_URL + "/app/*"}
              element={<App />}
            />
            <Route
              path={process.env.REACT_APP_CLIENT_URL + "/login/callback"}
              render={LoginCallback}
            />
            <Route
              path={process.env.REACT_APP_CLIENT_URL + "*"}
              element={<NotFound />}
            />
          </Routes>
        </BrowserRouter>
      </Security>
    </div>
  );
}

export default Root;
