import { createTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import React from "react";
import { ThemeProvider } from "styled-components";
import style from "../../css/meetings.module.css";

function Linechart(props) {
  const theme = createTheme();

  return (
    <div className={style.linechart + " linechart"}>
      <div className={style.chart_header}>
        <font>{props.title}</font>
        <font>
          <label>{props.subtitle}</label> <label>{props.subtitle_focus}</label>
        </font>
      </div>
      <div
        style={{
          width: "100%",
          overflowX: "scroll",
          justifyContent: "center",
        }}
      >
        <div>
          {props.data.length > 0 ? (
            <ThemeProvider theme={theme}>
              <LineChart
                xAxis={[
                  {
                    tickPlacement: "start",
                    position: "top",
                    data: props.data.map(
                      (point) => point.dateTime.split(" ")[1]
                    ),
                    scaleType: "point",
                    label: "1324",
                    disableTicks: true,
                  },
                ]}
                yAxis={[
                  {
                    axisLine: false, // y축 축선 숨김
                    tickLine: false, // y축 눈금선 숨김
                    label: null, // y축 레이블 숨김

                    //   reverse: true,
                  },
                ]}
                series={[
                  {
                    data: props.data.map((data) =>
                      props.value === "bitrateInput"
                        ? data.bitrateInput
                        : props.value === "bitrateOutput"
                        ? data.bitrateOutput
                        : props.value === "latencyInput"
                        ? data.latencyInput
                        : props.value === "latencyOutput"
                        ? data.latencyOutput
                        : props.value === "jitterInput"
                        ? data.jitterInput
                        : props.value === "jitterOutput"
                        ? data.jitterOutput
                        : props.value === "maxLossInput"
                        ? data.maxLossInput
                        : props.value === "maxLossOutput"
                        ? data.maxLossOutput
                        : props.value === "resolutionInput"
                        ? data.resolutionInput
                        : props.value === "resolutionOutput"
                        ? data.resolutionOutput
                        : props.value === "frameRateInput"
                        ? data.frameRateInput
                        : props.value === "frameRateOutput"
                        ? data.frameRateOutput
                        : props.value === "maxRssi"
                        ? data.maxRssi
                        : props.value === "minRssi"
                        ? data.minRssi
                        : props.value === "avgRssi"
                        ? data.avgRssi
                        : props.value === "zoomMinCpuUsage"
                        ? data.zoomMinCpuUsage
                        : props.value === "zoomAvgCpuUsage"
                        ? data.zoomAvgCpuUsage
                        : props.value === "zoomMaxCpuUsage"
                        ? data.zoomMaxCpuUsage
                        : props.value === "systemMaxCpuUsage"
                        ? data.systemMaxCpuUsage
                        : data.id
                    ),
                    connectNulls: false,
                  },
                ]}
                height={120}
                width={props.data.length * 80}
                margin={{ top: 10, bottom: 20 }}
                grid={{ horizontal: true, vertical: true }}
              />
            </ThemeProvider>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Linechart;
