import React, { useEffect, useState } from "react";
import style from "../../css/menu.module.css";
import { useLocation } from "react-router-dom";
function Menubtn(props) {
  const location = useLocation();
  const [id, setid] = useState("");
  useEffect(() => {
    if (location.pathname.split("/")[2] === "monitoring") {
      setid(
        location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3]
      );
    } else {
      setid(location.pathname.split("/")[2]);
    }
  }, [location]);
  const [ishover, sethover] = useState(false);
  const img_url = () => {
    if (ishover) {
      return props.data.img + "_a";
    } else {
      if (props.data.key === id) {
        return props.data.img + "_a";
      } else {
        return props.data.img;
      }
    }
  };
  const isAdmin = () => {
    return props.isAdmin ? "admin/" : "";
  };

  return props.open ? (
    <div
      className={
        props.data.key === id
          ? style.usedBtn + " cursor"
          : style.openBtn + " cursor"
      }
      onMouseOver={() => sethover(true)}
      onMouseOut={() => sethover(false)}
    >
      <img
        className={style.openImg}
        alt=""
        src={
          process.env.REACT_APP_CLIENT_URL +
          "img/menu/" +
          isAdmin() +
          img_url() +
          ".png"
        }
      />
      {props.data.label}
    </div>
  ) : (
    <div
      className={
        props.data.key === id
          ? style.usedBtn_C + " cursor"
          : style.closedBtn + " cursor"
      }
      onMouseOver={() => sethover(true)}
      onMouseOut={() => sethover(false)}
    >
      <img
        className={style.openImg}
        alt=""
        src={
          process.env.REACT_APP_CLIENT_URL +
          "img/menu/" +
          isAdmin() +
          img_url() +
          ".png"
        }
      />
      <div style={{ textAlign: "center" }}>{props.data.c_label}</div>
    </div>
  );
}

export default Menubtn;
