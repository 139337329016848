// @ts-nocheck
import { Modal, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";

function IpExcelUploadPopup({ isOpen, close, refresh, user }) {
  /** Message Alert */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
    });
  };

  const fileRef = useRef();
  const [file, setFile] = useState(null);

  const uploadExcel = () => {
    if (file === null) {
      alertMsg("warning", "No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("createEmail", user.createEmail);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("/admin/internalip/upload", formData, config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            alertMsg("success", res.data.message);
            closeHandler();
          } else {
            alertMsg("error", res.data.message);
          }
        }
      })
      .catch((err) => {
        alertMsg("error", "Excel Upload Error");
      });
  };

  const closeHandler = () => {
    setFile(null);
    close();
    refresh();
  };

  useEffect(() => {
    if (isOpen) {
      setFile(null);
    }
  }, [isOpen]);

  const downloadExcelForm = () => {
    const config = {
      responseType: "blob",
    };

    let url = "/admin/internalip/form";

    axios.post(url, null, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", "Internal IP Form.xlsx");

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Modal
      className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={435}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div
          className={comp.modal_title}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          IP Excel Upload
          <button className={comp.uploadFormBtn} onClick={downloadExcelForm}>
            <img
              className={comp.btnImg}
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/icon/download_icon.png"
              }
            />
            Form
          </button>
        </div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section className={comp.modal_content}>
        <div className={comp.modal_inputArea}>
          {/* <div className={comp.modal_inputTitle}>Group Name</div> */}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => setFile(e.target.files[0])}
            ref={fileRef}
            multiple={false}
            accept=".xls, .xlsx"
          />
          <div
            className={comp.modal_excelInput}
            onClick={() => fileRef.current.click()}
          >
            {file === null ? "Upload File" : file.name}
            {/* Upload File */}
            <img
              className={comp.btnImg}
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/icon/upload_icon.png"
              }
            />
          </div>
        </div>
      </section>
      <section className={comp.modal_btnArea}>
        <button
          className={`${comp.modal_btn} ${comp.blueBtn}`}
          onClick={uploadExcel}
        >
          Upload
        </button>
        <button
          className={`${comp.modal_btn} ${comp.whiteBtn}`}
          onClick={closeHandler}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
}

export default IpExcelUploadPopup;
