import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/participants.module.css";
import Selectbox from "../../components/search/selectbox";
import Datepicker from "../../components/search/datepicker";
import Searchbtn from "../../components/search/searchbtn";
import Inputtext from "../../components/search/inputtext";
import Excelbtn from "../../components/search/excelbtn";
import { useLocation, useNavigate } from "react-router-dom";
import Participanttable from "../../components/table/participanttable";
import Datetimepicker from "../../components/search/datetimepicker";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import axios from "axios";
import Issuelist from "../../components/monitoring/issuelist";
import { format } from "date-fns";

function Participants() {
  const searchtypelist = [
    { key: "nickName", label: "Nick Name" },
    { key: "email", label: "Email" },
    { key: "location", label: "Location" },
    { key: "dataCenter", label: "Data Center" },
  ];

  const [run, setrun] = useState(true);
  const [count, setcount] = useState(0);
  const [dateRefresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [type, settype] = useState(true);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const Hour = String(date.getHours()).padStart(2, "0");
    const Minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${Hour}:${Minutes}`;
  };

  const getWeekBeforeToday = () => {
    const today = new Date();
    const weekBefore = new Date();
    weekBefore.setDate(today.getDate() - 7);

    return weekBefore;
  };

  useEffect(() => {
    if (location.pathname.split("/")[3] === "live") {
      settype(true);
      setInput({
        keyword1: "",
        keyword2: "",
        searchOption1: "nickName",
        searchOption2: "nickName",
      });
      setSearchInfo({
        keyword1: "",
        keyword2: "",
        searchOption1: "nickName",
        searchOption2: "nickName",
      });
      setrun(true);
      setRefresh(true);
    } else if (location.pathname.split("/")[3] === "past") {
      settype(false);
      setInput({
        keyword1: "",
        keyword2: "",
        searchOption1: "nickName",
        searchOption2: "nickName",
        startTime: getFormattedDate(getWeekBeforeToday()),
        endTime: getFormattedDate(new Date()),
      });
      setSearchInfo({
        keyword1: "",
        keyword2: "",
        searchOption1: "nickName",
        searchOption2: "nickName",
        startTime: getFormattedDate(getWeekBeforeToday()),
        endTime: getFormattedDate(new Date()),
      });
      setRefresh(true);
      setrun(true);
    } else {
      settype(true);
    }
  }, [location]);

  const [input, setInput] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "nickName",
    searchOption2: "nickName",
    startTime: null,
    endTime: null,
  });
  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "",
    searchOption2: "",
    startTime: null,
    endTime: null,
  });

  const search = () => {
    setSearchInfo(input);
    setPage(1);
    setrun(true);
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setrun(true);
  };

  const [issueList, setIssueList] = useState([]);
  const getissueList = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        keyWord2: searchInfo.keyword2,
        searchOption1: searchInfo.searchOption1,
        searchOption2: searchInfo.searchOption2,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 15,
        limit: 15,
      },
    };
    let url = "";
    if (type) {
      url = "/user/live/meetings/participants";
    } else {
      url = "/user/past/meetings/participants";
    }

    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        setIssueList(res.data.data);
        setrun(false);
        setRefresh(false);
      }
    });
  }, [page, searchInfo, type]);

  const getissueListCount = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        keyWord2: searchInfo.keyword2,
        searchOption1: searchInfo.searchOption1,
        searchOption2: searchInfo.searchOption2,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 15,
        limit: 15,
      },
    };
    let url = "";
    if (type) {
      url = "/user/live/meetings/participantCounts";
    } else {
      url = "/user/past/meetings/participantCounts";
    }
    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        setcount(res.data.data.pages);
      }
    });
  }, [page, searchInfo, type]);

  useEffect(() => {
    if (run) {
      getissueList();
      getissueListCount();
    }
  }, [getissueList, run, getissueListCount]);

  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };

    const data = {
      keyWord1: searchInfo.keyword1,
      keyWord2: searchInfo.keyword2,
      searchOption1: searchInfo.searchOption1,
      searchOption2: searchInfo.searchOption2,
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
    };

    let url = "";
    if (type) {
      url = "/user/live/meetings/participants/excel";
    } else {
      url = "/user/past/meetings/participants/excel";
    }
    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;

      let startTime =
        searchInfo.startTime === null ? "Entire Period" : searchInfo.startTime;
      let endTime =
        searchInfo.endTime === null ? "Entire Period" : searchInfo.endTime;

      const today = new Date();
      const todayform = format(today, "yyyy-MM-dd hh:mm");
      let liveDate = startTime + " ~ " + endTime;
      if (
        type &&
        searchInfo.startTime === null &&
        searchInfo.endTime === null
      ) {
        liveDate = todayform;
      }
      if (type) {
        link.setAttribute(
          "download",
          "LiveParticipants(" + liveDate + ").xlsx"
        );
      } else {
        link.setAttribute(
          "download",
          "PastParticipants(" + startTime + " ~ " + endTime + ").xlsx"
        );
      }

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={style.participants_wrapper}>
      <div className={style.select_type}>
        <div
          className={type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/participants/live")
          }
        >
          LIVE
        </div>
        <div
          className={!type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/participants/past")
          }
        >
          PAST
        </div>
      </div>
      <div className={style.participants_body}>
        <section className={style.participants_search}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {type ? (
              ""
            ) : (
              <div className={style.participants_search_datebox}>
                <font>Meeting Time :</font>
                <Datetimepicker
                  value={input.startTime}
                  onChange={(value) => setInput({ ...input, startTime: value })}
                  refresh={dateRefresh}
                />
                <font> - </font>
                <Datetimepicker
                  value={input.endTime}
                  onChange={(value) => setInput({ ...input, endTime: value })}
                  refresh={dateRefresh}
                />
              </div>
            )}

            <Selectbox
              width={134}
              selectlist={searchtypelist}
              placeHolder=""
              value={input.searchOption1}
              onChange={(val) => setInput({ ...input, searchOption1: val })}
            />
            <Inputtext
              value={input.keyword1}
              onChange={(value) => setInput({ ...input, keyword1: value })}
            />
            <span>and</span>

            <Selectbox
              width={134}
              selectlist={searchtypelist}
              placeHolder=""
              value={input.searchOption2}
              onChange={(val) => setInput({ ...input, searchOption2: val })}
            />
            <Inputtext
              value={input.keyword2}
              onChange={(value) => setInput({ ...input, keyword2: value })}
            />
            <Searchbtn search={() => search()} />
          </div>
          <div onClick={() => excelDownload()}>
            <Excelbtn />
          </div>
        </section>
        <section style={{ height: "100%" }}>
          <Participanttable list={issueList} />
          <Stack spacing={1} style={{ display: "flex" }}>
            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              boundaryCount={4}
            />
          </Stack>
        </section>
      </div>
    </div>
  );
}

export default Participants;
