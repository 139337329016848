import React, { useCallback, useEffect, useState } from "react";
import oktaConfig from "./oktaConfig";
import OktaAuth from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import menuConfig from "./menuConfig";
import { useDispatch } from "react-redux";
import { insertUserInfo } from "../../redux/slices/userSlice";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function RouteDetector({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [init, setInit] = useState(true);
  const [isCheck, setIsCheck] = useState(false);
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState(null);

  const initUserInfo = useCallback(async () => {
    const payload = {};

    await oktaAuth
      .getUser()
      .then((res) => {
        setUser(res);
        payload.name = res.name;
        payload.email = res.email;
      })
      .catch(() => {
        navigate("/");
      });
    const accessToken = await oktaAuth.getAccessToken();
    const groups = await oktaAuth.token.decode(accessToken).payload.groups;
    await setGroups(groups);
    payload.groups = groups;
    await dispatch(insertUserInfo(payload));
    await setIsCheck(true);
  }, [dispatch, navigate]);

  const authenticationEntryPoint = useCallback(() => {
    let result = true;
    const location = window.location.pathname;
    menuConfig.admin.forEach((item) => {
      if (
        location.includes(item.key) &&
        !groups.includes(menuConfig.adminRole)
      ) {
        result = false;
      }
    });
    return result;
  }, [groups]);

  useEffect(() => {
    if (init) {
      initUserInfo();
      setInit(false);
    }
  }, [initUserInfo, init]);

  useEffect(() => {
    if (isCheck) {
      if (user === null || groups === null || !authenticationEntryPoint()) {
        navigate(-1);
      }
      setIsCheck(false);
    }
  }, [authenticationEntryPoint, groups, isCheck, navigate, user]);

  return <>{children}</>;
}

export default RouteDetector;
