import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "../../css/monitoring.module.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function Statustable(props) {
  const [tableList, setTableList] = useState([""]);
  const location = useLocation();
  const check_hold = (userId) => {
    axios.put("/user/monitoring/userHold/" + userId).then((res) => {
      if (location.pathname.split("/")[3] === "vipmonitoring") {
        gettableList("Y");
      } else {
        gettableList(null);
      }
    });
  };

  const gettableList = useCallback((data) => {
    let config = {
      params: {
        groupId: data,
      },
    };

    axios.get("/user/monitoring/getUserStatus", config).then((res) => {
      if (res.status === 200) {
        setTableList(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname.split("/")[3] === "vipmonitoring") {
      gettableList("Y");
    } else {
      gettableList(null);
    }
  }, [location, gettableList, props.refresh]);

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [tableList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  return (
    <div className={style.statusBoard}>
      <div className={style.statusBoard_header}>StatusBoard</div>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.table_tr}>
              <div></div>
              <div>Nick Name</div>
              <div>Email</div>
              <div>Meeting ID</div>
              <div>Start Time</div>
              <div>Data Center</div>
              <div>Location</div>
              <div>Device</div>
              <div>IP Address</div>
              <div>Version</div>
              <div>Status</div>
            </div>
          </div>
          <div className={style.table_body_wrapper}>
            <div className={style.table_body}>
              {tableList.map((table, index) => (
                <div
                  className={
                    table.status === "In Meeting"
                      ? `${style.Meeting + " " + style.table_tr}`
                      : table.status === "Error"
                      ? `${style.Error + " " + style.table_tr}`
                      : table.status === "Warning"
                      ? `${style.Warning + " " + style.table_tr}`
                      : table.status === "Online"
                      ? `${style.Online + " " + style.table_tr}`
                      : table.status === "Offline"
                      ? `${style.Offline + " " + style.table_tr}`
                      : `${style.Offline + " " + style.table_tr}`
                  }
                  key={index}
                  data-index={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                >
                  <div className="cursor" onClick={() => check_hold(table.id)}>
                    {table.hold === "Y" ? (
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/monitoring/holdy.png"
                        }
                      />
                    ) : (
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/monitoring/holdn.png"
                        }
                      />
                    )}
                  </div>
                  <div title={table.displayNm}>{table.displayNm}</div>
                  <div
                    className={
                      table.status === "In Meeting" ? "cursor" : style.none
                    }
                    onClick={() =>
                      table.status === "In Meeting"
                        ? window.open(
                            process.env.REACT_APP_CLIENT_URL +
                              "app/meetings/participantdetail/live/" +
                              table.participantId +
                              "/?id=" +
                              encodeURIComponent(table.meetingId),
                            "",
                            "width=1920px, height=900px, scrollbars=yes, resizable=no"
                          )
                        : ""
                    }
                  >
                    <font title={table.email}>{table.email}</font>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL + "img/common/link.png"
                      }
                    />
                  </div>
                  <div
                    className={
                      table.status === "In Meeting" ? "cursor" : style.none
                    }
                    onClick={() =>
                      table.status === "In Meeting"
                        ? window.open(
                            process.env.REACT_APP_CLIENT_URL +
                              "app/meetings/meetingdetail/live/?id=" +
                              encodeURIComponent(table.meetingId),
                            "",
                            "width=1920px, height=900px, scrollbars=yes, resizable=no"
                          )
                        : ""
                    }
                  >
                    <font> {table.meetingNumber}</font>

                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL + "img/common/link.png"
                      }
                    />
                  </div>
                  <div>{table.startTime}</div>
                  <div>{table.dataCenter}</div>
                  <div>{table.location}</div>
                  <div>{table.device}</div>
                  <div>{table.ip}</div>
                  <div>{table.version}</div>
                  <div>{table.status}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {tableList.length}
      </div>
    </div>
  );
}

export default Statustable;
