import React, { useEffect, useState } from "react";
import Issuelist from "../../components/monitoring/issuelist";
import Errorinfo from "../../components/monitoring/errorinfo";
import style from "../../css/monitoring.module.css";
import Statustable from "../../components/monitoring/statustable";
import Loading from "../../components/common/loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Vctable from "../../components/monitoring/vctable";
function Monitoring() {
  const [issuestatus, setissuestatus] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const [initialY, setInitialY] = useState(0);
  const [height, setHeight] = useState(550);
  const [errorheight, setErrorHeight] = useState(50);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    if (window.outerHeight > e.clientY) {
      setIsResizing(true);
    }
    setInitialY(e.clientY);
  };
  const handleMouseUp = () => {
    setIsResizing(false);
  };
  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      const newHeight = height + e.clientY - initialY;
      setInitialY(e.clientY);
      var eheight = issuestatus
        ? window.innerHeight - newHeight - 335
        : window.innerHeight - newHeight - 55;
      if (eheight > 50) {
        if (newHeight >= 200) {
          setErrorHeight(eheight);
          setHeight(newHeight);
        }
      }
    }
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    if (issuestatus) {
      setErrorHeight(50);
      setHeight(window.innerHeight - 331 - 55);
    } else {
      setErrorHeight(window.innerHeight - height - 55);
    }
  }, [issuestatus]);

  const [refresh, setrefresh] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (refresh) {
        setrefresh(false);
      } else {
        setrefresh(true);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [refresh]);

  const [loading, setloading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.split("/")[3] === "monitoring") {
    } else if (location.pathname.split("/")[3] === "vipmonitoring") {
    } else if (location.pathname.split("/")[3] === "vcmonitoring") {
    } else {
      navigate(process.env.REACT_APP_CLIENT_URL + "app/error");
    }
  }, [location]);

  return (
    <div>
      <Loading loading={loading} />
      <section>
        <Issuelist
          issuestatus={(status) => setissuestatus(status)}
          refresh={refresh}
          setrefresh={setrefresh}
          setLoad={setloading}
        />
      </section>
      <section
        className={
          location.pathname.split("/")[3] === "vcmonitoring"
            ? "issue_closed"
            : issuestatus
            ? "issue_open"
            : "issue_closed"
        }
      >
        <section style={{ height }}>
          {location.pathname.split("/")[3] === "vcmonitoring" ? (
            <Vctable />
          ) : (
            <Statustable />
          )}
        </section>
        <section
          className={style.errorinfo_section}
          style={{
            height: errorheight,
          }}
        >
          <div
            className={style.errorinfo_resize + " cursor"}
            onMouseDown={handleMouseDown}
          >
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/monitoring/move.png"}
            />
          </div>
          <div className={style.errorinfo}>
            <Errorinfo refresh={refresh} setLoad={setloading} />
          </div>
        </section>
      </section>

      {/*  */}
    </div>
  );
}

export default Monitoring;
