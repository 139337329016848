// @ts-nocheck
import { Modal, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";

function IpSettingPopup({ isOpen, value, close, refresh, ipList, user }) {
  /** Message Alert */
  const [messageApi, contextHolder] = message.useMessage();
  const url = "/admin/internalip";

  const [data, setData] = useState({ ipAddress: "" });
  const ipInput = useRef();

  /** Event Handler */
  const saveHandler = () => {
    if (data.ipAddress !== "") {
      const tmp = ipList.filter((ip) => ip.ipAddress === data.ipAddress);
      if (tmp.length > 0) {
        messageApi.error("IP already exists");
        return;
      }
      if (value === null) save(data);
      else modify(data);
    } else {
      messageApi.warning("Please enter IP Address");
      ipInput.current.focus();
    }
  };
  const closeHandler = () => {
    setData({ ipAddress: "" });
    refresh();
    close();
  };

  const save = (data) => {
    const ipData = {
      ...data,
      createEmail: user.createEmail,
    };
    axios
      .post(url, ipData)
      .then((res) => {
        if (res.status === 201) {
          messageApi.success("IP Registered Successfully");
          refresh();
          closeHandler();
        }
      })
      .catch((err) => messageApi.error("IP Registration Failed"));
  };
  const modify = (data) => {
    const ipData = {
      ...data,
      createEmail: user.createEmail,
    };
    axios
      .put(url, ipData)
      .then((res) => {
        if (res.status === 200) {
          messageApi.success("IP Modified Successfully");
          refresh();
          closeHandler();
        }
      })
      .catch((err) => messageApi.error("IP Modification Failed"));
  };

  useEffect(() => {
    if (isOpen) {
      setData(value === null ? { ipAddress: "" } : value);
    }
  }, [isOpen, value]);

  return (
    <Modal
      className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={435}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div className={comp.modal_title}>
          IP {value === null ? "Registration" : "Modify"}
        </div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section className={comp.modal_content}>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>IP</div>
          <input
            type="text"
            className={`${comp.modal_inputBox} ${comp.input_border}`}
            value={data.ipAddress}
            onChange={(e) => setData({ ...data, ipAddress: e.target.value })}
            ref={ipInput}
          />
        </div>
      </section>
      <section className={comp.modal_btnArea}>
        <button
          className={`${comp.modal_btn} ${comp.blueBtn}`}
          onClick={saveHandler}
        >
          Save
        </button>
        <button
          className={`${comp.modal_btn} ${comp.whiteBtn}`}
          onClick={closeHandler}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
}

export default IpSettingPopup;
