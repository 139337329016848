const menuConfig = {
  // coupang
  //adminRole: "RMAC_ZoomQSS_ADMIN",
  // riv
  adminRole: "COUPANG_ADMIN",
  user: [
    {
      label: "Monitoring",
      c_label: "Monitoring",
      key: "monitoring/monitoring",
      img: "vcmonitoring",
    },
    {
      label: "VIP monitoring",
      c_label: "VIP",
      key: "monitoring/vipmonitoring",
      img: "vipmonitoring",
    },
    {
      label: "VC monitoring",
      c_label: "VC",
      key: "monitoring/vcmonitoring",
      img: "vcmonitoring",
    },
    {
      label: "Meetings",
      c_label: "Meetings",
      key: "meetings",
      img: "meetings",
    },
    {
      label: "Participants",
      c_label: "Participants",
      key: "participants",
      img: "participants",
    },
  ],
  admin: [
    {
      label: "Monitoring Account",
      c_label: "Monitoring Account",
      key: "monitoringAccount",
      img: "zoomAccount",
    },
    {
      label: "Group",
      c_label: "Group",
      key: "group",
      img: "group",
    },
    {
      label: "Threshold Setting",
      c_label: "Threshold Setting",
      key: "thresholdSetting",
      img: "thresholdSetting",
    },
    {
      label: "Slack Setting",
      c_label: "Slack Setting",
      key: "slackSetting",
      img: "slackSetting",
    },
    {
      label: "Internal IP",
      c_label: "Internal IP",
      key: "ipSetting",
      img: "ipSetting",
    },
    {
      label: "Login Log",
      c_label: "Login Log",
      key: "loginLog",
      img: "loginLog",
    },
    {
      label: "Activity Log",
      c_label: "Activity Log",
      key: "activityLog",
      img: "activityLog",
    },
    {
      label: "Quality report",
      c_label: "Quality",
      key: "qualityreport",
      img: "qualityreport",
    },
    {
      label: "Issue report",
      c_label: "Issue",
      key: "issuereport",
      img: "issuereport",
    },
    {
      label: "Monthly Report",
      c_label: "Monthly Report",
      key: "monthlyreport",
      img: "issuereport",
    },
    {
      label: "Data Verify",
      c_label: "Data Verify",
      key: "dataverify",
      img: "issuereport",
    },
  ],
};

export default menuConfig;
