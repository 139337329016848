import React from "react";
import style from "../../css/table.module.css";
import { useLocation, useNavigate } from "react-router-dom";
function Participanttable(props) {
  const header = [
    { title: "Nick Name" },
    { title: "Email" },
    { title: "Join Time" },
    { title: "Leave Time" },
    { title: "Duration" },
    { title: "Meeting ID" },
    { title: "Region (Data Center)" },
    { title: "IP Address" },
    { title: "Leave Reason" },
  ];
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.particiapnttable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {props.list.map((table, index) => (
              <div
                className={style.particiapnttable_tr}
                key={index}
                data-index={index}
              >
                <div>{table.displayNm}</div>
                <div
                  className="cursor"
                  onClick={() =>
                    navigate(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/meetingdetail/" +
                        (location.pathname.split("/")[3] === "past"
                          ? "past/?id=" + encodeURIComponent(table.meetingId)
                          : "live/?id=" + encodeURIComponent(table.meetingId)),
                      "",
                      "width=1920px, height=900px, scrollbars=yes, resizable=no"
                    )
                  }
                >
                  <font> {table.email}</font>{" "}
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL + "img/common/link.png"
                    }
                  />
                </div>
                <div>{table.startTime}</div>
                <div>{table.endTime}</div>
                <div>{table.duration}</div>
                <div>{table.meetingNumber}</div>
                <div>{table.dataCenter}</div>
                <div>{table.signalingIpAddress}</div>
                <div title={table.leaveReason}>{table.leaveReason}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Participanttable;
