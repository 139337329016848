import React from "react";
import style from "../../css/adminComp.module.css";

function EditBtn({ onclickEvent }) {
  return (
    <button className={`${style.btn} ${style.edit}`} onClick={onclickEvent}>
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/edit_icon.png"}
      />
      <div>Edit</div>
    </button>
  );
}

export default EditBtn;
