// @ts-nocheck
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { message, Switch } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import EditBtn from "../../components/admin/editBtn";
import SaveBtn from "../../components/admin/saveBtn";
import tableStyle from "../../css/admin/thresholdTable.module.css";
import style from "../../css/report.module.css";
import OktaAuth from "@okta/okta-auth-js";
import oktaConfig from "../../components/common/oktaConfig";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function ThresholdSetting() {
  /** Message Alert */
  const [messageApi, contextHolder] = message.useMessage();

  const [isEdit, setIsEdit] = useState(false);
  const [init, setInit] = useState(true);
  const [threshold, setThreshold] = useState({});
  const [user, setUser] = useState({ createNm: "", createEmail: "" });

  const getThreshold = useCallback(() => {
    oktaAuth.getUser().then((res) => {
      setUser({ createNm: res.name, createEmail: res.email });
    });
    axios
      .get("/admin/alertthreshold")
      .then((res) => {
        if (res.status === 200) {
          setThreshold(res.data.data[0]);
        }
      })
      .catch((err) => {
        messageApi.error("Failed to load logs");
      });
  }, [messageApi]);

  useEffect(() => {
    if (init) {
      getThreshold();
      setInit(false);
    }
  }, [getThreshold, init]);

  /** Button Event Handler */
  const saveHandler = () => {
    if (Object.entries(threshold).length > 0) {
      const url = "/admin/alertthreshold/" + threshold.id;
      const thresholdData = { ...threshold, createEmail: user.createEmail };
      axios
        .put(url, thresholdData)
        .then((res) => {
          if (res.status === 200) {
            messageApi.success("Changes Successfully Saved");
            setIsEdit(false);
            getThreshold();
          }
        })
        .catch((err) => {
          messageApi.error("Failed to save changes");
        });
    } else {
      messageApi.warning("No Threshold data to Modify");
    }
  };

  return (
    <div className={style.report_wrapper}>
      {contextHolder}
      <section className={style.report_search}>
        <div style={{ display: "flex" }}>
          {isEdit ? (
            <SaveBtn onclickEvent={saveHandler} />
          ) : (
            <EditBtn onclickEvent={() => setIsEdit(true)} />
          )}
        </div>
      </section>
      <section className={tableStyle.table}>
        {/* TABLE */}
        <div className={tableStyle.header}>
          <div>Item</div>
          <div>Metric</div>
          <div>Slack</div>
          <div>Monitoring</div>
          <div>Receiving</div>
          <div>Sending</div>
        </div>
        <div className={tableStyle.audio}>
          <div>Audio</div>
          <div>
            <div className={tableStyle.single_h}>Bitrate</div>
            <div className={tableStyle.single_h}>Latency</div>
            <div className={tableStyle.single_h}>Jitter</div>
            <div className={tableStyle.single_h}>Avg Packet Loss</div>
            <div className={tableStyle.single_h}>Packet Loss</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioBitrateSlackYn === "Y"
                        ? true
                        : threshold.audioBitrateSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioBitrateSlackYn: checked ? "Y" : "N",
                        audioBitrateMonitoringYn: checked
                          ? "Y"
                          : threshold.audioBitrateMonitoringYn,
                      })
                    }
                  />
                ) : threshold.audioBitrateSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioBitrateSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioLatencySlackYn === "Y"
                        ? true
                        : threshold.audioLatencySlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioLatencySlackYn: checked ? "Y" : "N",
                        audioLatencyMonitoringYn: checked
                          ? "Y"
                          : threshold.audioLatencyMonitoringYn,
                      })
                    }
                  />
                ) : threshold.audioLatencySlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioLatencySlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioJitterSlackYn === "Y"
                        ? true
                        : threshold.audioJitterSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioJitterSlackYn: checked ? "Y" : "N",
                        audioJitterMonitoringYn: checked
                          ? "Y"
                          : threshold.audioJitterMonitoringYn,
                      })
                    }
                  />
                ) : threshold.audioJitterSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioJitterSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioAvgLossSlackYn === "Y"
                        ? true
                        : threshold.audioAvgLossSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioAvgLossSlackYn: checked ? "Y" : "N",
                        audioAvgLossMonitoringYn: checked
                          ? "Y"
                          : threshold.audioAvgLossMonitoringYn,
                      })
                    }
                  />
                ) : threshold.audioAvgLossSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioAvgLossSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioMaxLossSlackYn === "Y"
                        ? true
                        : threshold.audioMaxLossSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioMaxLossSlackYn: checked ? "Y" : "N",
                        audioMaxLossMonitoringYn: checked
                          ? "Y"
                          : threshold.audioMaxLossMonitoringYn,
                      })
                    }
                  />
                ) : threshold.audioMaxLossSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioMaxLossSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioBitrateMonitoringYn === "Y"
                        ? true
                        : threshold.audioBitrateMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioBitrateMonitoringYn: checked ? "Y" : "N",
                        audioBitrateSlackYn: !checked
                          ? "N"
                          : threshold.audioBitrateSlackYn,
                      })
                    }
                  />
                ) : threshold.audioBitrateMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioBitrateMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioLatencyMonitoringYn === "Y"
                        ? true
                        : threshold.audioLatencyMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioLatencyMonitoringYn: checked ? "Y" : "N",
                        audioLatencySlackYn: !checked
                          ? "N"
                          : threshold.audioLatencySlackYn,
                      })
                    }
                  />
                ) : threshold.audioLatencyMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioLatencyMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioJitterMonitoringYn === "Y"
                        ? true
                        : threshold.audioJitterMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioJitterMonitoringYn: checked ? "Y" : "N",
                        audioJitterSlackYn: !checked
                          ? "N"
                          : threshold.audioJitterSlackYn,
                      })
                    }
                  />
                ) : threshold.audioJitterMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioJitterMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioAvgLossMonitoringYn === "Y"
                        ? true
                        : threshold.audioAvgLossMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioAvgLossMonitoringYn: checked ? "Y" : "N",
                        audioAvgLossSlackYn: !checked
                          ? "N"
                          : threshold.audioAvgLossSlackYn,
                      })
                    }
                  />
                ) : threshold.audioAvgLossMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioAvgLossMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.audioMaxLossMonitoringYn === "Y"
                        ? true
                        : threshold.audioMaxLossMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        audioMaxLossMonitoringYn: checked ? "Y" : "N",
                        audioMaxLossSlackYn: !checked
                          ? "N"
                          : threshold.audioMaxLossSlackYn,
                      })
                    }
                  />
                ) : threshold.audioMaxLossMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioMaxLossMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioBitrateInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioBitrateInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioBitrateInput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioLatencyInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioLatencyInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioLatencyInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioJitterInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioJitterInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioJitterInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioAvgLossInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioAvgLossInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioAvgLossInput}</span>
                )}
                <span>%</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioMaxLossInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioMaxLossInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioMaxLossInput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioBitrateOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioBitrateOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioBitrateOutput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioLatencyOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioLatencyOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioLatencyOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioJitterOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioJitterOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioJitterOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioAvgLossOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioAvgLossOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioAvgLossOutput}</span>
                )}
                <span>%</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioMaxLossOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioMaxLossOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioMaxLossOutput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
        <div className={tableStyle.video}>
          <div>Video</div>
          <div>
            <div className={tableStyle.single_h}>Bitrate</div>
            <div className={tableStyle.single_h}>Latency</div>
            <div className={tableStyle.single_h}>Jitter</div>
            <div className={tableStyle.single_h}>Avg Packet Loss</div>
            <div className={tableStyle.single_h}>Packet Loss</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoBitrateSlackYn === "Y"
                        ? true
                        : threshold.videoBitrateSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoBitrateSlackYn: checked ? "Y" : "N",
                        videoBitrateMonitoringYn: checked
                          ? "Y"
                          : threshold.videoBitrateMonitoringYn,
                      })
                    }
                  />
                ) : threshold.videoBitrateSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoBitrateSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoLatencySlackYn === "Y"
                        ? true
                        : threshold.videoLatencySlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoLatencySlackYn: checked ? "Y" : "N",
                        videoLatencyMonitoringYn: checked
                          ? "Y"
                          : threshold.videoLatencyMonitoringYn,
                      })
                    }
                  />
                ) : threshold.videoLatencySlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoLatencySlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoJitterSlackYn === "Y"
                        ? true
                        : threshold.videoJitterSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoJitterSlackYn: checked ? "Y" : "N",
                        videoJitterMonitoringYn: checked
                          ? "Y"
                          : threshold.videoJitterMonitoringYn,
                      })
                    }
                  />
                ) : threshold.videoJitterSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoJitterSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoAvgLossSlackYn === "Y"
                        ? true
                        : threshold.videoAvgLossSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoAvgLossSlackYn: checked ? "Y" : "N",
                        videoAvgLossMonitoringYn: checked
                          ? "Y"
                          : threshold.videoAvgLossMonitoringYn,
                      })
                    }
                  />
                ) : threshold.videoAvgLossSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoAvgLossSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoMaxLossSlackYn === "Y"
                        ? true
                        : threshold.videoMaxLossSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoMaxLossSlackYn: checked ? "Y" : "N",
                        videoMaxLossMonitoringYn: checked
                          ? "Y"
                          : threshold.videoMaxLossMonitoringYn,
                      })
                    }
                  />
                ) : threshold.videoMaxLossSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoMaxLossSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoBitrateMonitoringYn === "Y"
                        ? true
                        : threshold.videoBitrateMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoBitrateMonitoringYn: checked ? "Y" : "N",
                        videoBitrateSlackYn: !checked
                          ? "N"
                          : threshold.videoBitrateSlackYn,
                      })
                    }
                  />
                ) : threshold.videoBitrateMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoBitrateMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoLatencyMonitoringYn === "Y"
                        ? true
                        : threshold.videoLatencyMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoLatencyMonitoringYn: checked ? "Y" : "N",
                        videoLatencySlackYn: !checked
                          ? "N"
                          : threshold.videoLatencySlackYn,
                      })
                    }
                  />
                ) : threshold.videoLatencyMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoLatencyMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoJitterMonitoringYn === "Y"
                        ? true
                        : threshold.videoJitterMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoJitterMonitoringYn: checked ? "Y" : "N",
                        videoJitterSlackYn: !checked
                          ? "N"
                          : threshold.videoJitterSlackYn,
                      })
                    }
                  />
                ) : threshold.videoJitterMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoJitterMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoAvgLossMonitoringYn === "Y"
                        ? true
                        : threshold.videoAvgLossMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoAvgLossMonitoringYn: checked ? "Y" : "N",
                        videoAvgLossSlackYn: !checked
                          ? "N"
                          : threshold.videoAvgLossSlackYn,
                      })
                    }
                  />
                ) : threshold.videoAvgLossMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoAvgLossMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.videoMaxLossMonitoringYn === "Y"
                        ? true
                        : threshold.videoMaxLossMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        videoMaxLossMonitoringYn: checked ? "Y" : "N",
                        videoMaxLossSlackYn: !checked
                          ? "N"
                          : threshold.videoMaxLossSlackYn,
                      })
                    }
                  />
                ) : threshold.videoMaxLossMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoMaxLossMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoBitrateInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoBitrateInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoBitrateInput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoLatencyInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoLatencyInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoLatencyInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoJitterInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoJitterInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoJitterInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoAvgLossInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoAvgLossInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoAvgLossInput}</span>
                )}
                <span>%</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoMaxLossInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoMaxLossInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoMaxLossInput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoBitrateOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoBitrateOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoBitrateOutput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoLatencyOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoLatencyOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoLatencyOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoJitterOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoJitterOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoJitterOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoAvgLossOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoAvgLossOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoAvgLossOutput}</span>
                )}
                <span>%</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoMaxLossOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoMaxLossOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoMaxLossOutput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={tableStyle.screen}>
          <div>Screen Sharing</div>
          <div>
            <div className={tableStyle.single_h}>Bitrate</div>
            <div className={tableStyle.single_h}>Latency</div>
            <div className={tableStyle.single_h}>Jitter</div>
            <div className={tableStyle.single_h}>Packet Loss</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asBitrateSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asBitrateSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asBitrateSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asBitrateSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asLatencySlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asLatencySlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asLatencySlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asLatencySlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asJitterSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asJitterSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asJitterSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asJitterSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asMaxLossSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asMaxLossSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asMaxLossSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asMaxLossSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asBitrateMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asBitrateMonitoringYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asBitrateMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asBitrateMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asLatencyMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asLatencyMonitoringYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asLatencyMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asLatencyMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asJitterMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asJitterMonitoringYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asJitterMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asJitterMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.asMaxLossMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asMaxLossMonitoringYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.asMaxLossMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.asMaxLossMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asBitrateInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asBitrateInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asBitrateInput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asLatencyInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asLatencyInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asLatencyInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asJitterInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asJitterInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asJitterInput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asMaxLossInput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asMaxLossInput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asMaxLossInput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asBitrateOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asBitrateOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asBitrateOutput}</span>
                )}
                <span>kbps</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asLatencyOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asLatencyOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asLatencyOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asJitterOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asJitterOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asJitterOutput}</span>
                )}
                <span>ms</span>
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.asMaxLossOutput}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        asMaxLossOutput: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.asMaxLossOutput}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
        </div> */}
        <div className={tableStyle.cpu}>
          <div>CPU Usage</div>
          <div>
            <div className={tableStyle.single_h}>Zoom Max</div>
            {/* <div className={tableStyle.single_h}>Zoom Avg</div> */}
            <div className={tableStyle.single_h}>System CPU</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.zoomMaxCpuUsageSlackYn === "Y"
                        ? true
                        : threshold.zoomMaxCpuUsageSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        zoomMaxCpuUsageSlackYn: checked ? "Y" : "N",
                        zoomMaxCpuUsageMonitoringYn: checked
                          ? "Y"
                          : threshold.zoomMaxCpuUsageMonitoringYn,
                      })
                    }
                  />
                ) : threshold.zoomMaxCpuUsageSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.zoomMaxCpuUsageSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            {/* <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.zoomAvgCpuUsageSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        zoomAvgCpuUsageSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.zoomAvgCpuUsageSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.zoomAvgCpuUsageSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div> */}
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.systemMaxCpuUsageSlackYn === "Y"
                        ? true
                        : threshold.systemMaxCpuUsageSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        systemMaxCpuUsageSlackYn: checked ? "Y" : "N",
                        systemMaxCpuUsageMonitoringYn: checked
                          ? "Y"
                          : threshold.systemMaxCpuUsageMonitoringYn,
                      })
                    }
                  />
                ) : threshold.systemMaxCpuUsageSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.systemMaxCpuUsageSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.zoomMaxCpuUsageMonitoringYn === "Y"
                        ? true
                        : threshold.zoomMaxCpuUsageMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        zoomMaxCpuUsageMonitoringYn: checked ? "Y" : "N",
                        zoomMaxCpuUsageSlackYn: !checked
                          ? "N"
                          : threshold.zoomMaxCpuUsageSlackYn,
                      })
                    }
                  />
                ) : threshold.zoomMaxCpuUsageMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.zoomMaxCpuUsageMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            {/* <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.zoomAvgCpuUsageMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        zoomAvgCpuUsageMonitoringYn: e.target.checked
                          ? "Y"
                          : "N",
                      })
                    }
                  />
                ) : threshold.zoomAvgCpuUsageMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.zoomAvgCpuUsageMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div> */}
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.systemMaxCpuUsageMonitoringYn === "Y"
                        ? true
                        : threshold.systemMaxCpuUsageMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        systemMaxCpuUsageMonitoringYn: checked ? "Y" : "N",
                        systemMaxCpuUsageSlackYn: !checked
                          ? "N"
                          : threshold.systemMaxCpuUsageSlackYn,
                      })
                    }
                  />
                ) : threshold.systemMaxCpuUsageMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.systemMaxCpuUsageMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.zoomMaxCpuUsage}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        zoomMaxCpuUsage: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.zoomMaxCpuUsage}</span>
                )}
                <span>%</span>
              </div>
            </div>
            {/* <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.zoomAvgCpuUsage}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        zoomAvgCpuUsage: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.zoomAvgCpuUsage}</span>
                )}
                <span>%</span>
              </div>
            </div> */}
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.systemMaxCpuUsage}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        systemMaxCpuUsage: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.systemMaxCpuUsage}</span>
                )}
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={tableStyle.quality}>
          <div>Quality Score</div>
          <div>
            <div className={tableStyle.single_h}>Video Min</div>
            <div className={tableStyle.single_h}>Audio Min</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.videoQualityScoreSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoQualityScoreSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.videoQualityScoreSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoQualityScoreSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.audioQualityScoreSlackYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioQualityScoreSlackYn: e.target.checked ? "Y" : "N",
                      })
                    }
                  />
                ) : threshold.audioQualityScoreSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioQualityScoreSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.videoQualityScoreMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoQualityScoreMonitoringYn: e.target.checked
                          ? "Y"
                          : "N",
                      })
                    }
                  />
                ) : threshold.videoQualityScoreMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.videoQualityScoreMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <input
                    type="checkbox"
                    checked={threshold.audioQualityScoreMonitoringYn === "Y"}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioQualityScoreMonitoringYn: e.target.checked
                          ? "Y"
                          : "N",
                      })
                    }
                  />
                ) : threshold.audioQualityScoreMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.audioQualityScoreMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.videoQualityScore}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        videoQualityScore: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.videoQualityScore}</span>
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.audioQualityScore}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        audioQualityScore: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.audioQualityScore}</span>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <div className={tableStyle.rssi}>
          <div>RSSI</div>
          <div>
            <div className={tableStyle.single_h}>Rssi Min</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.minRssiSlackYn === "Y"
                        ? true
                        : threshold.minRssiSlackYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        minRssiSlackYn: checked ? "Y" : "N",
                        minRssiMonitoringYn: checked
                          ? "Y"
                          : threshold.minRssiMonitoringYn,
                      })
                    }
                  />
                ) : threshold.minRssiSlackYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.minRssiSlackYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div className={tableStyle.input_box}>
                {isEdit ? (
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={
                      threshold.minRssiMonitoringYn === "Y"
                        ? true
                        : threshold.minRssiMonitoringYn === "N"
                        ? false
                        : null
                    }
                    onChange={(checked) =>
                      setThreshold({
                        ...threshold,
                        minRssiMonitoringYn: checked ? "Y" : "N",
                        minRssiSlackYn: !checked
                          ? "N"
                          : threshold.minRssiSlackYn,
                      })
                    }
                  />
                ) : threshold.minRssiMonitoringYn === "Y" ? (
                  <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                ) : threshold.minRssiMonitoringYn === "N" ? (
                  <CloseIcon style={{ color: "#d74343" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.minRssi}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        minRssi: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.minRssi}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={tableStyle.issues}>
          <div>Issues</div>
          <div>
            <div className={tableStyle.single_h}>Regions</div>
            <div className={tableStyle.single_h}>CPU</div>
          </div>
          <div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.regionCnt}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        regionCnt: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.regionCnt}</span>
                )}
              </div>
            </div>
            <div className={`${tableStyle.input_tr} ${tableStyle.single_h}`}>
              <div
                className={
                  isEdit ? tableStyle.input_box_edit : tableStyle.input_box
                }
              >
                {isEdit ? (
                  <input
                    type="number"
                    value={threshold.cpuCnt}
                    onChange={(e) =>
                      setThreshold({
                        ...threshold,
                        cpuCnt: Number(e.target.value),
                      })
                    }
                  />
                ) : (
                  <span>{threshold.cpuCnt}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ThresholdSetting;

// A_BITRATE_IN: 0,
//     audioBitrateOutput: 0,
//     A_LATENCY_IN: 150,
//     audioLatencyOutput: 150,
//     A_JITTER_IN: 40,
//     audioJitterOutput: 40,
//     A_PACKETLOSS_IN: 40,
//     audioMaxLossOutput: 40,
//     videoBitrateInput: 0,
//     videoBitrateOutput: 0,
//     videoLatencyInput: 150,
//     videoLatencyOutput: 150,
//     videoJitterInput: 40,
//     videoJitterOutput: 40,
//     videoMaxLossInput: 40,
//     videoMaxLossOutput: 40,
//     asBitrateInput: 0,
//     asBitrateOutput: 0,
//     asLatencyInput: 150,
//     asLatencyOutput: 150,
//     asJitterInput: 40,
//     asJitterOutput: 40,
//     asMaxLossInput: 40,
//     asMaxLossOutput: 40,
//     zoomMaxCpuUsage: 70,
//     zoomAvgCpuUsage: 70,
//     systemMaxCpuUsage: 90,
//     videoQualityScore: 1,
//     audioQualityScore: 1,
//     minRssi: -30,
