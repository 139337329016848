import { useSelector } from "react-redux";
import style from "../../css/menu.module.css";
import menuConfig from "../common/menuConfig";
import Menubtn from "../menubtn/menubtn";
import { useNavigate } from "react-router-dom";
function Menu(props) {
  const navigate = useNavigate();
  const payload = useSelector((state) => state.user);

  const authenticationEntryPoint = (key) => {
    if (!payload.groups.includes(menuConfig.adminRole)) {
      return;
    }
    navigate(key);
  };

  return (
    <div className={props.isopen ? style.open : style.closed}>
      <div className={style.header}>
        {props.isopen ? (
          <img
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/menu/logo.png"}
          />
        ) : (
          <img
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/menu/logo_c.png"}
          />
        )}
      </div>

      <div
        className={style.actionBtn + " cursor"}
        onClick={() => props.openbtn(!props.isopen)}
      >
        {props.isopen ? (
          <img
            className={style.menu_arrow}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/menu/closeArrow.png"}
          />
        ) : (
          <img
            className={style.menu_arrow}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/menu/openArrow.png"}
          />
        )}
      </div>
      <div className={style.menu_wrapper}>
        <div className={style.menu_container}>
          <div
            className={props.isopen ? style.menuTitle : style.menuTitle_closed}
          >
            MENU
          </div>
          <div className={style.menuList}>
            {menuConfig.user.map((menu, index) => (
              <div onClick={() => navigate(menu.key)} key={index}>
                <Menubtn data={menu} open={props.isopen} isAdmin={false} />
              </div>
            ))}
          </div>
          {!props.isopen && <div className={style.h_divider}></div>}
          {payload.groups.includes(menuConfig.adminRole) && (
            <div>
              <div
                className={
                  props.isopen ? style.menuTitle : style.menuTitle_closed
                }
                style={{ marginTop: props.isopen ? "24px" : "0" }}
              >
                ADMIN
              </div>
              <div className={style.adminMenuList}>
                {menuConfig.admin.map((menu, index) => (
                  <div
                    onClick={() => authenticationEntryPoint(menu.key)}
                    key={index}
                  >
                    <Menubtn data={menu} open={props.isopen} isAdmin={true} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Menu;
