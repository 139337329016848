import React from "react";
import style from "../../css/table.module.css";
import { useNavigate } from "react-router-dom";
function Livemeetingtable(props) {
  const header = [
    { title: "Meeting ID" },
    { title: "Start Time" },
    { title: "Participants" },
    { title: "Host Email" },
    { title: "Host Nick Name" },
  ];
  const navigate = useNavigate();
  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.meetinglive_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {props.list.map((table, index) => (
              <div
                className={style.meetinglive_tr}
                key={index}
                data-index={index}
              >
                <div
                  className="cursor"
                  onClick={() =>
                    navigate(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/meetingdetail/live/?id=" +
                        encodeURIComponent(table.meetingId)
                    )
                  }
                >
                  {table.meetingNumber}{" "}
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL + "img/common/link.png"
                    }
                  />
                </div>
                <div>{table.startTime}</div>
                <div>{table.participants}</div>
                <div>{table.hostMail}</div>
                <div>{table.hostNm}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Livemeetingtable;
