import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
function Datepicker({ value, setValue }) {
  return (
    <div style={{ outline: "none", border: "none" }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        // sx={{ width: 150, height: 35, fontSize: 13 }}
      >
        <DatePicker
          sx={{ width: 135, height: 35, fontSize: 13 }}
          slotProps={{ textField: { size: "small", fontSize: 13 } }}
          format="YYYY-MM-DD"
          size="small"
          value={dayjs(value)}
          onChange={(newValue) =>
            setValue(dayjs(newValue).format("YYYY-MM-DD"))
          }
        />
      </LocalizationProvider>
    </div>
  );
}

export default Datepicker;
