// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import style from "../../css/admin/dataVerifyTable.module.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Spin } from "antd";

function DataVerifyTable({ dataList, isLoading }) {
  const header = [
    { title: "Meeting ID" },
    { title: "Start Time" },
    { title: "Host Email" },
    { title: "Host Name" },
    { title: "Verified" },
  ];

  /** TABLE CONTROLLER */
  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [dataList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  const getActionType = (action) => {
    switch (action) {
      case "C":
        return "Create";
      case "U":
        return "Update";
      case "D":
        return "Delete";
      default:
        return "-";
    }
  };
  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.logtable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "7px",
                }}
              >
                <Spin />
              </div>
            ) : (
              dataList.map((data, index) => (
                <div
                  className={style.logtable_tr}
                  key={index}
                  data-index={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                >
                  <div title={data.meetingId}>{data.meetingId}</div>
                  <div title={data.startTime}>{data.startTime}</div>
                  <div title={data.hostEmail}>{data.hostEmail}</div>
                  <div title={data.hostName}>{data.hostName}</div>
                  <div title={data.verifyYn}>
                    {data.verifyYn === "Y" ? (
                      <CheckOutlinedIcon style={{ color: "#0073e9" }} />
                    ) : data.verifyYn === "N" ? (
                      <CloseIcon style={{ color: "#d74343" }} />
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {dataList.length}
      </div>
    </div>
  );
}

export default DataVerifyTable;
