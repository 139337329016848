import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";

/**
 * 파일명: notFound.js
 * 작성자: 정지윤
 * 작성일자: 24.08.04
 * 설명: 404 page
 */
function NotFound() {
  //navigate
  const navigate = useNavigate();

  const pageBackEventHandler = () => {
    navigate(process.env.REACT_APP_CLIENT_URL + "app/monitoring/monitoring");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="요청하신 페이지를 찾을 수 없습니다."
      style={{
        width: "100%",
        height: "100%",
      }}
      extra={
        <Button type="primary" onClick={() => pageBackEventHandler()}>
          홈으로
        </Button>
      }
    />
  );
}

export default NotFound;
