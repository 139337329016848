// @ts-nocheck
import { message } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import DataVerifyTable from "../../components/table/dataVerifyTable";
import style from "../../css/adminComp.module.css";

function DataVerify() {
  const [init, setInit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();

  const getDataList = useCallback(() => {
    let url = "/admin/dataverify";
    axios
      .get(url)
      .then((res) => {
        setDataList(res.data.data);
      })
      .catch((err) => messageApi.error("Failed to load Verified Data List"))
      .finally(() => setIsLoading(false));
  }, [messageApi]);

  useEffect(() => {
    if (init) {
      getDataList();
      setInit(false);
    }
  }, [getDataList, init]);

  return (
    <div className={style.wrapper}>
      {contextHolder}
      <section>
        {/* TABLE */}
        <DataVerifyTable dataList={dataList} isLoading={isLoading} />
      </section>
    </div>
  );
}

export default DataVerify;
