import React from "react";
import style from "../../css/adminComp.module.css";

function FileUpdateBtn({ onclickEvent }) {
  return (
    <button
      className={`${style.btn} ${style.fileUpdate}`}
      onClick={onclickEvent}
    >
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/upload_icon.png"}
      />
      <div>File Update</div>
    </button>
  );
}

export default FileUpdateBtn;
