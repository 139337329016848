import React from "react";
import style from "../../css/search.module.css";
function Searchbtn(props) {
  return (
    <div onClick={() => props.search()} className={style.searchBtn + " cursor"}>
      Search
    </div>
  );
}

export default Searchbtn;
