// @ts-nocheck
import { Modal, Switch, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";

function GroupPopup({ isOpen, value, close, refresh, groupList, user }) {
  /** Message Alert */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
    });
  };

  const url = "/admin/group";

  const [data, setData] = useState({ deptNm: "" });
  const groupInput = useRef();

  /** Event Handler */
  const saveHandler = () => {
    if (data.deptNm !== "") {
      const tmp = groupList.filter((group) => group.deptNm === data.deptNm);
      if (tmp.length > 0 && value === null) {
        alertMsg("error", "Group already exists");
        return;
      }
      if (value === null) save(data);
      else modify(data);
    } else {
      alertMsg("warning", "Please enter group name");
      groupInput.current.focus();
    }
  };

  const save = (data) => {
    const groupData = {
      ...data,
      createEmail: user.createEmail,
    };
    axios
      .post(url, groupData)
      .then((res) => {
        if (res.status === 201) {
          alertMsg("success", "Group Successfully Saved");
          refresh();
          closeHandler();
        }
      })
      .catch((err) => alertMsg("error", "Group Register Failed"));
  };

  const modify = (data) => {
    const groupData = {
      ...data,
      createNm: user.createNm,
      createEmail: user.createEmail,
    };
    axios
      .put(url, groupData)
      .then((res) => {
        if (res.status === 200) {
          alertMsg("success", "Group Modified Successfully");
          refresh();
          closeHandler();
        }
      })
      .catch((err) => {
        alertMsg("error", "Group Modify Failed");
      });
  };

  const closeHandler = () => {
    setData({ deptNm: "", vipYn: "N" });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setData(value === null ? { deptNm: "", vipYn: "N" } : value);
    }
  }, [isOpen, value]);

  return (
    <Modal
      className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={435}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div className={comp.modal_title}>
          {value === null ? "Create" : "Modify"} Group
        </div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section className={comp.modal_content}>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Group Name</div>
          <input
            type="text"
            className={`${comp.modal_inputBox} ${comp.input_border}`}
            value={data.deptNm}
            onChange={(e) => setData({ ...data, deptNm: e.target.value })}
            ref={groupInput}
          />
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            VIP
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={
                data.vipYn === "Y" ? true : data.vipYn === "N" ? false : null
              }
              onChange={(checked) =>
                setData({ ...data, vipYn: checked ? "Y" : "N" })
              }
            />
          </div>
        </div>
      </section>
      <section className={comp.modal_btnArea}>
        <button
          className={`${comp.modal_btn} ${comp.blueBtn}`}
          onClick={saveHandler}
        >
          Save
        </button>
        <button
          className={`${comp.modal_btn} ${comp.whiteBtn}`}
          onClick={closeHandler}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
}

export default GroupPopup;
