import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/meetings.module.css";
import Selectbox from "../../components/search/selectbox";
import Datepicker from "../../components/search/datepicker";
import Searchbtn from "../../components/search/searchbtn";
import Inputtext from "../../components/search/inputtext";
import Excelbtn from "../../components/search/excelbtn";

import { useLocation, useNavigate } from "react-router-dom";
import Participanttable from "../../components/table/participanttable";
import Livemeetingtable from "../../components/table/livemeetingtable";
import moment from "moment";
import axios from "axios";
import Datetimepicker from "../../components/search/datetimepicker";
import { Pagination, Stack } from "@mui/material";
import Pastmeetingtable from "../../components/table/Pastmeetingtable";
import { format } from "date-fns";

function Meetings() {
  const searchtypelist = [
    { key: "meetingId", label: "Meeting ID" },
    { key: "email", label: "Host Email" },
  ];
  const [run, setrun] = useState(true);
  const [count, setcount] = useState(0);
  const [countsAll, setcountsAll] = useState(0);
  const [dateRefresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [type, settype] = useState(true);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const Hour = String(date.getHours()).padStart(2, "0");
    const Minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${Hour}:${Minutes}`;
  };

  const getWeekBeforeToday = () => {
    const today = new Date();
    const weekBefore = new Date();
    weekBefore.setDate(today.getDate() - 7);

    return weekBefore;
  };

  useEffect(() => {
    if (location.pathname.split("/")[3] === "live") {
      settype(true);
      setInput({
        keyword1: "",
        searchOption1: "meetingId",
      });
      setSearchInfo({
        keyword1: "",
        searchOption1: "meetingId",
      });
      setrun(true);
      setRefresh(true);
    } else if (location.pathname.split("/")[3] === "past") {
      settype(false);
      setInput({
        keyword1: "",
        searchOption1: "meetingId",
        startTime: getFormattedDate(getWeekBeforeToday()),
        endTime: getFormattedDate(new Date()),
      });
      setSearchInfo({
        keyword1: "",
        searchOption1: "meetingId",
        startTime: getFormattedDate(getWeekBeforeToday()),
        endTime: getFormattedDate(new Date()),
      });
      setrun(true);
      setRefresh(true);
    } else {
      settype(true);
    }
  }, [location]);

  const [input, setInput] = useState({
    keyword1: "",
    searchOption1: "meetingId",
    startTime: null,
    endTime: null,
  });
  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    searchOption1: "meetingId",
    startTime: null,
    endTime: null,
  });

  const search = () => {
    setSearchInfo(input);
    setPage(1);
    setrun(true);
  };
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setrun(true);
  };

  const [meetingList, setMeetingList] = useState([]);
  const getMeetingList = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        searchOption1: searchInfo.searchOption1,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 20,
        limit: 20,
      },
    };
    let url = "";
    if (type) {
      url = "/user/live/meetings/list";
    } else {
      url = "/user/past/meetings/list";
    }
    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        setMeetingList(res.data.data);
        setrun(false);
        setRefresh(false);
      }
    });
  }, [page, searchInfo, type]);

  const getMeetingCount = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        searchOption1: searchInfo.searchOption1,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        offset: (page - 1) * 20,
        limit: 20,
      },
    };
    let url = "";
    if (type) {
      url = "/user/live/meetings/counts";
    } else {
      url = "/user/past/meetings/counts";
    }

    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        setcount(res.data.data.pages);
      }
    });
  }, [page, searchInfo, type]);

  const getCountsAll = useCallback(() => {
    let url = "/user/live/meetings/countsAll";

    axios.get(url, "").then((res) => {
      if (res.status === 200) {
        setcountsAll(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (run) {
      getMeetingList();
      getMeetingCount();
      getCountsAll();
    }
  }, [getMeetingList, run, getMeetingCount, getCountsAll]);
  // excel
  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };

    const data = {
      keyWord1: searchInfo.keyword1,
      searchOption1: searchInfo.searchOption1,
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
    };

    let startTime =
      searchInfo.startTime === null ? "Entire Period" : searchInfo.startTime;
    let endTime =
      searchInfo.endTime === null ? "Entire Period" : searchInfo.endTime;
    const today = new Date();
    const todayform = format(today, "yyyy-MM-dd hh:mm");
    let url = "";
    if (type) {
      url = "/user/live/meetings/list/excel";
    } else {
      url = "/user/past/meetings/list/excel";
    }
    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (type) {
        link.setAttribute("download", "LiveMeeting(" + todayform + ").xlsx");
      } else {
        link.setAttribute(
          "download",
          "PastMeeting(" + startTime + " ~ " + endTime + ").xlsx"
        );
      }

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={style.meetings_wrapper}>
      <div className={style.select_type}>
        <div
          className={type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/live")
          }
        >
          LIVE
        </div>
        <div
          className={!type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/past")
          }
        >
          PAST
        </div>
      </div>
      <div className={style.meetings_body}>
        <section className={style.meetings_search}>
          <div className={style.meetings_live}>
            <font>Meetings</font>
            <div>
              <font>{countsAll}</font>
              <font>In Meeting</font>
            </div>
          </div>
          <div className="flex" style={{ gap: "65px", height: "35px" }}>
            <div style={{ display: "flex" }}>
              {!type ? (
                <div className={style.meetings_search_datebox}>
                  <font>Start Time :</font>
                  <Datetimepicker
                    value={input.startTime}
                    onChange={(value) =>
                      setInput({ ...input, startTime: value })
                    }
                    refresh={dateRefresh}
                  />
                  <font> - </font>
                  <Datetimepicker
                    value={input.endTime}
                    onChange={(value) => setInput({ ...input, endTime: value })}
                    refresh={dateRefresh}
                  />
                </div>
              ) : (
                ""
              )}

              <Selectbox
                width={134}
                selectlist={searchtypelist}
                placeHolder=""
                value={input.searchOption1}
                onChange={(val) => setInput({ ...input, searchOption1: val })}
              />
              <Inputtext
                value={input.keyword1}
                onChange={(value) => setInput({ ...input, keyword1: value })}
              />
              <Searchbtn search={() => search()} />
            </div>
            <div onClick={() => excelDownload()}>
              <Excelbtn />
            </div>
          </div>
        </section>
        <section style={{ height: "100%" }}>
          {type ? (
            <Livemeetingtable list={meetingList} />
          ) : (
            <Pastmeetingtable list={meetingList} />
          )}

          <Stack spacing={1} style={{ display: "flex" }}>
            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              boundaryCount={4}
            />
          </Stack>
        </section>
      </div>
    </div>
  );
}

export default Meetings;
