import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    name: "",
    email: "",
    groups: []
  },
  reducers: {
    insertUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.groups = action.payload.groups;
    },
    deleteUserInfo: (state) => {
      state.name = "";
      state.email = "";
      state.groups = [];
    },
  },
});

export default userSlice;

export const { insertUserInfo, deleteUserInfo } = userSlice.actions;
