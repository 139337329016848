import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/monitoring.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
function Errorinfo(props) {
  const location = useLocation();
  const [errorList, setErrorList] = useState([]);
  const [infoList, setInfoList] = useState([]);

  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);

  const [limitInfo, setLimitInfo] = useState(100);
  const [totalInfo, setTotalInfo] = useState(0);

  const getErrorList = useCallback(
    (data) => {
      let config = {
        params: {
          selectId: data,
          limit: limit,
        },
      };
      props.setLoad(true);

      axios
        .get("/user/monitoring/getErrors", config)
        .then((res) => {
          if (res.status === 200) {
            setErrorList(res.data.data);
            props.setLoad(false);
          }
        })
        .catch(() => {
          props.setLoad(false);
        });
    },
    [limit]
  );

  const getErrorCount = useCallback((data) => {
    let config = {
      params: {
        groupId: data,
      },
    };
    axios.get("/user/monitoring/getErrorCounts", config).then((res) => {
      if (res.status === 200) {
        setTotal(res.data.data.total);
      }
    });
  }, []);

  const getInfoList = useCallback(
    (data) => {
      let config = {
        params: {
          selectId: data,
          limit: limit,
        },
      };
      props.setLoad(true);
      axios
        .get("/user/monitoring/getInfo", config)
        .then((res) => {
          if (res.status === 200) {
            setInfoList(res.data.data);
            props.setLoad(false);
          }
        })
        .catch(() => {
          props.setLoad(false);
        });
    },
    [limitInfo]
  );

  const getInfoCount = useCallback((data) => {
    let config = {
      params: {
        groupId: data,
      },
    };
    axios.get("/user/monitoring/getInfoCounts", config).then((res) => {
      if (res.status === 200) {
        setTotalInfo(res.data.data.total);
      }
    });
  }, []);

  const { ref: ref1, inView: inview1 } = useInView({
    threshold: 1.0, // 1.0 means that the target element is fully visible
  });
  useEffect(() => {
    if (inview1) {
      if (total > limit) {
        let limitData = limit + 100;
        setLimit(limitData);
      }
    }
  }, [inview1]);

  const { ref: ref2, inView: inview2 } = useInView({
    threshold: 1.0, // 1.0 means that the target element is fully visible
  });

  useEffect(() => {
    if (inview2) {
      if (totalInfo > limitInfo) {
        let limitData = limitInfo + 100;
        setLimitInfo(limitData);
      }
    }
  }, [inview2]);

  useEffect(() => {
    if (location.pathname.split("/")[3] === "vipmonitoring") {
      getErrorList("Y");
      getErrorCount("Y");
    } else {
      getErrorList(null);
      getErrorCount(null);
    }
  }, [location.pathname, getErrorList, props.refresh, getErrorCount]);

  useEffect(() => {
    if (location.pathname.split("/")[3] === "vipmonitoring") {
      getInfoList("Y");
      getInfoCount("Y");
    } else {
      getInfoList(null);
      getInfoCount(null);
    }
  }, [location.pathname, getInfoList, getInfoCount, props.refresh]);

  return (
    <div className={style.errorinfo_wrapper}>
      <div className={style.errors}>
        <div className={style.errorinfo_header}>
          <div className={style.errorinfo_headertxt}>Errors</div>
          <div className={style.errorinfo_counts}>{total}</div>
        </div>
        <div className={style.error_table_header}>
          <div></div>
          <div>UserName</div>
          <div>Date</div>
          <div>Contents</div>
          <div>Meeting Number</div>
          <div>Confirm</div>
        </div>
        <div className={style.errorinfo_table}>
          {errorList.map((table, index) => (
            <div className={style.error_table_tr} key={index}>
              <div
                className={table.hold === "N" ? style.green : style.red}
              ></div>
              <div title={table.displayNm}>{table.displayNm}</div>
              <div>{table.dateTime}</div>
              <div title={table.detailType + " : " + table.detailValue}>
                {table.detailType} : {table.detailValue}
              </div>
              <div>{table.meetingNumber}</div>
              <div title={table.confirmId}>{table.confirmId}</div>
            </div>
          ))}
          <div ref={ref1} style={{ height: "10px", width: "200px" }} />
        </div>
      </div>
      <div className={style.infos}>
        <div className={style.errorinfo_header}>
          <div className={style.errorinfo_headertxt}>Info</div>
          <div className={style.errorinfo_counts}>{totalInfo}</div>
        </div>
        <div className={style.info_table_header}>
          <div>UserName</div>
          <div>Date</div>
          <div>Contents</div>
          <div>Meeting Number</div>
        </div>

        <div className={style.errorinfo_table}>
          {infoList.map((table, index) => (
            <div className={style.info_table_tr} key={index}>
              <div title={table.displayNm}>{table.displayNm}</div>
              <div>{table.dateTime}</div>
              <div title={table.content}>{table.content}</div>
              <div>{table.meetingNumber}</div>
            </div>
          ))}
          <div ref={ref2} style={{ height: "10px", width: "10px" }} />
        </div>
      </div>
    </div>
  );
}

export default Errorinfo;
