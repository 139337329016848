import React from "react";
import style from "../../css/adminComp.module.css";

function CreateBtn({ onclickEvent }) {
  return (
    <button className={`${style.btn} ${style.create}`} onClick={onclickEvent}>
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/create_icon.png"}
      />
      <div>Create</div>
    </button>
  );
}

export default CreateBtn;
