// @ts-nocheck
import { FormControl, MenuItem, Select } from "@mui/material";
import { message, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import DeleteBtn from "../../components/admin/deleteBtn";
import Inputtext from "../../components/admin/inputtext";
import Searchbtn from "../../components/admin/searchbtn";
import Selectbox from "../../components/admin/selectbox";
import VcDevicePopup from "../../components/popup/vcDevicePopup";
import ZoomAccountPopup from "../../components/popup/zoomAccountPopup";
import tableStyle from "../../css/admin/monitoringAccountTable.module.css";
import compStyle from "../../css/adminComp.module.css";
import style from "../../css/report.module.css";
import OktaAuth from "@okta/okta-auth-js";
import oktaConfig from "../../components/common/oktaConfig";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function MonitoringAccount() {
  const [init, setInit] = useState(true); // page init
  const [accountList, setAccountList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [searchFilter, setSearchFilter] = useState({ type: "email", text: "" });
  const [user, setUser] = useState({ createEmail: "" });
  // Popup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVcModalOpen, setIsVcModalOpen] = useState(false);
  // searchFilter - select options
  const searchtypelist = [
    { key: "email", label: "Email" },
    { key: "nickName", label: "NickName" },
  ];

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = useCallback(
    (type, msg) => {
      messageApi.open({
        type: type,
        content: msg,
      });
    },
    [messageApi]
  );

  /** Search status */
  const [isNoResult, setIsNoResult] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAccountList = useCallback(() => {
    oktaAuth.getUser().then((res) => {
      setUser({ createEmail: res.email });
    });
    setIsNoResult(false);
    setIsLoading(true);
    let url = "/admin/monitoringaccount";
    if (searchFilter.text !== "") {
      url += `/${searchFilter.type}/${searchFilter.text}`;
    }
    axios
      .get(url)
      .then((res) => {
        setIsNoResult(res.data.data.length === 0);
        setAccountList(res.data.data);
      })
      .catch((err) => {
        alertMsg("error", "Failed to load account list");
      })
      .finally(() => setIsLoading(false));
  }, [alertMsg, searchFilter]);

  const getGroupList = useCallback(() => {
    let url = "/admin/group";
    axios
      .get(url)
      .then((res) => {
        setGroupList(res.data.data);
      })
      .catch((err) => {
        alertMsg("error", "Group List Request Fail");
      });
  }, [alertMsg]);

  useEffect(() => {
    if (init) {
      getAccountList();
      getGroupList();
      setInit(false);
    }
  }, [getAccountList, getGroupList, init]);

  /** TABLE */
  const header = [
    { title: "" },
    { title: "Email" },
    { title: "Nick Name" },
    { title: "Group" },
    { title: "Participant ID" },
    { title: "IP Address" },
  ];

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [accountList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  /* CHECKBOX */
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let newCheckedItems;
    if (checked) {
      newCheckedItems = [...checkedItems, Number(value)];
    } else {
      newCheckedItems = checkedItems.filter((item) => item !== Number(value));
    }
    setCheckedItems(newCheckedItems);
  };

  /* Button Event Handler */
  const deleteHandler = () => {
    if (checkedItems.length === 0) {
      alertMsg("info", "No items selected");
      return;
    }

    let list = [];
    checkedItems.forEach((item) => {
      list.push(accountList.filter((account) => account.id === item)[0]);
    });

    list = list.map((item) => ({
      ...item,
      createEmail: user.createEmail,
    }));

    const config = {
      data: list,
    };

    axios
      .delete("/admin/monitoringaccount", config)
      .then((res) => {
        if (res.status === 204) {
          setCheckedItems([]);
          alertMsg("success", "Account(s) Deleted Successfully");
        }
      })
      .catch((err) => alertMsg("error", "Account(s) Delete Failed"))
      .finally(getAccountList);
  };

  /** Account Group Modify Handler */
  const handleGroupModify = (value, accData) => {
    let newDeptNm;
    if (value === null) {
      newDeptNm = "No Group";
    } else {
      newDeptNm = groupList.filter((group) => group.deptCd === value)[0].deptNm;
    }
    const newAccount = {
      ...accData,
      deptCd: value,
      deptNm: newDeptNm,
      createEmail: user.createEmail,
    };

    axios
      .put("/admin/monitoringaccount", newAccount)
      .then((res) => {
        if (res.status === 200) {
          alertMsg("success", "Group Changed Success");
        }
      })
      .catch((err) => alertMsg("error", "Group Change Failed"))
      .finally(getAccountList);
  };

  return (
    <div className={style.report_wrapper}>
      {contextHolder}
      <ZoomAccountPopup
        isOpen={isModalOpen}
        close={() => {
          getAccountList();
          setIsModalOpen(false);
        }}
        registeredList={accountList}
        user={user}
      />
      <VcDevicePopup
        isOpen={isVcModalOpen}
        close={() => {
          getAccountList();
          setIsVcModalOpen(false);
        }}
        registeredList={accountList}
        user={user}
      />

      <section className={style.report_search}>
        <div style={{ display: "flex" }}>
          <Selectbox
            width={134}
            selectlist={searchtypelist}
            value={searchFilter.type}
            setValue={(e) => {
              setSearchFilter({ ...searchFilter, type: e.target.value });
            }}
          />
          <Inputtext
            value={searchFilter.text}
            setValue={(e) => {
              setSearchFilter({ ...searchFilter, text: e.target.value });
            }}
            enter={getAccountList}
          />
          <Searchbtn onclickHandler={getAccountList} />
        </div>
        <div className="taskBtn">
          <button
            className={`${compStyle.btn} ${compStyle.create}`}
            onClick={() => setIsVcModalOpen(true)}
          >
            <img
              className={compStyle.btnImg}
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/icon/create_icon.png"
              }
            />
            <div>Device</div>
          </button>
          <button
            className={`${compStyle.btn} ${compStyle.create}`}
            onClick={() => setIsModalOpen(true)}
          >
            <img
              className={compStyle.btnImg}
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/icon/create_icon.png"
              }
            />
            <div>Zoom</div>
          </button>
          {/* <CreateBtn onclickEvent={() => setIsModalOpen(true)} /> */}
          <DeleteBtn onclickEvent={deleteHandler} />
        </div>
      </section>

      {/* TABLE */}
      <section>
        <div className={tableStyle.statusBoard}>
          <div className={tableStyle.Statusboard_body}>
            <div className={tableStyle.table}>
              <div className={tableStyle.table_head}>
                <div className={tableStyle.zoomAccounttable_tr}>
                  {header.map((table, index) => (
                    <div key={index}> {table.title} </div>
                  ))}
                </div>
              </div>
              <div className={tableStyle.table_body}>
                {isLoading ? (
                  <div className="resultBox">
                    <Spin />
                  </div>
                ) : (
                  accountList.length === 0 && (
                    <div className="resultBox">No results found</div>
                  )
                )}
                {!isLoading &&
                  accountList.map((account, index) => (
                    <div
                      className={tableStyle.zoomAccounttable_tr}
                      key={index}
                      data-index={index}
                      ref={(el) => (rowRefs.current[index] = el)}
                    >
                      <div>
                        <input
                          type="checkbox"
                          value={account.id}
                          checked={checkedItems.includes(account.id)}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div title={account.email}>{account.email}</div>
                      <div title={account.displayNm}>{account.displayNm}</div>
                      <div>
                        <div className={tableStyle.selectBox}>
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={account.deptCd}
                              label="Age"
                              onChange={(e) => {
                                handleGroupModify(e.target.value, account);
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                height: "25px",
                                minWidth: "230px",
                              }}
                            >
                              <MenuItem value={null}>No Group</MenuItem>
                              {groupList.map((group) => (
                                <MenuItem value={group.deptCd}>
                                  {group.deptNm}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div title={account.participantUserId}>
                        {account.participantUserId}
                      </div>
                      <div title={account.accountIp}>{account.accountIp}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={tableStyle.statusBoard_footer}>
            Status :{" "}
            <font>
              {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
            </font>{" "}
            of {accountList.length}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MonitoringAccount;
