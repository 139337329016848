// @ts-nocheck
import { message } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import AdminSelectbox from "../../components/admin/adminSelectbox";
import Datepicker from "../../components/admin/datepicker";
import FileDownloadBtn from "../../components/admin/fileDownloadBtn";
import Inputtext from "../../components/admin/inputtext";
import Searchbtn from "../../components/admin/searchbtn";
import LogTable from "../../components/table/logTable";
import style from "../../css/adminComp.module.css";

const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const getWeekBeforeToday = () => {
  const today = new Date();
  const weekBefore = new Date();
  weekBefore.setDate(today.getDate() - 7);

  return weekBefore;
};

function LoginLog() {
  const [init, setInit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState({
    type: "email",
    text: "",
    start: getFormattedDate(getWeekBeforeToday()),
    end: getFormattedDate(new Date()),
  });
  const [logList, setLogList] = useState([]);
  const searchtypelist = [
    { key: "email", label: "Email" },
    { key: "name", label: "Name" },
  ];

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = useCallback(
    (type, msg) => {
      messageApi.open({
        type: type,
        content: msg,
      });
    },
    [messageApi]
  );

  const getLogList = useCallback(() => {
    setIsLoading(true);
    let url = `/admin/log/login/${searchFilter.start}/${searchFilter.end}`;
    if (searchFilter.text !== "") {
      url += `/${searchFilter.type}/${searchFilter.text}`;
    }

    axios
      .get(url)
      .then((res) => {
        setLogList(res.data.data);
      })
      .catch((err) => messageApi("Failed to load Login Log "))
      .finally(() => setIsLoading(false));
  }, [messageApi, searchFilter]);

  useEffect(() => {
    if (init) {
      getLogList();
      setInit(false);
    }
  }, [getLogList, init]);

  /** Download Login Log to Excel */
  const downloadExcel = () => {
    const config = {
      responseType: "blob",
    };
    const data = logList;

    let url = "/admin/log/login/excel";

    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        "Login Log(" + searchFilter.start + " ~ " + searchFilter.end + ").xlsx"
      );

      document.body.appendChild(link);
      link.click();
    });
  };

  /** check Date validity - date picker */
  const dateHandler = (type, dateValue) => {
    const currentDate = { start: searchFilter.start, end: searchFilter.end };
    if (type === "S") {
      const newStartDate = new Date(dateValue);
      const currentEndDate = new Date(currentDate.end);
      const isValid = newStartDate <= currentEndDate;

      if (isValid) {
        setSearchFilter({ ...searchFilter, start: dateValue });
      } else {
        alertMsg("warning", "Invalid start date");
        setSearchFilter({ ...searchFilter, start: dateValue, end: dateValue });
      }
    } else if (type === "E") {
      const currentStartDate = new Date(currentDate.start);
      const newEndDate = new Date(dateValue);
      const isValid = currentStartDate <= newEndDate;

      if (isValid) {
        setSearchFilter({ ...searchFilter, end: dateValue });
      } else {
        alertMsg("warning", "Invalid end date");
        setSearchFilter({ ...searchFilter, start: dateValue, end: dateValue });
      }
    }
  };

  /** get log list on date change */
  useEffect(() => {
    getLogList();
  }, [searchFilter.start, searchFilter.end]);

  return (
    <div className={style.wrapper}>
      {contextHolder}
      <section className={style.search_wrapper}>
        <div style={{ display: "flex" }}>
          <div className={style.search_datebox}>
            <Datepicker
              value={searchFilter.start}
              setValue={(value) => dateHandler("S", value)}
            />
            <font> - </font>
            <Datepicker
              value={searchFilter.end}
              setValue={(value) => dateHandler("E", value)}
            />
          </div>
          <AdminSelectbox
            width={134}
            selectlist={searchtypelist}
            value={searchFilter.type}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, type: e.target.value })
            }
          />
          <Inputtext
            value={searchFilter.text}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, text: e.target.value })
            }
            enter={getLogList}
          />
          <Searchbtn onclickHandler={getLogList} />
        </div>
        <FileDownloadBtn onclickEvent={downloadExcel} />
      </section>
      <section>
        {/* TABLE */}
        <LogTable dataList={logList} isLoading={isLoading} />
      </section>
    </div>
  );
}

export default LoginLog;
