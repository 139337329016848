import React from "react";
import style from "../../css/monitoring.module.css";
function Issuecard(props) {
  return (
    <div
      key={props.index}
      className={
        props.data.messageType === "Warning"
          ? style.warning_card + " " + style.issue_card
          : style.error_card + " " + style.issue_card
      }
    >
      <div className={style.card1}>
        <div>
          <div
            className={
              props.data.type === "Warning"
                ? style.warning_icon
                : style.error_icon
            }
          ></div>
          <label
            className={
              props.data.type === "ZR"
                ? style.VC
                : props.data.type === "CRC"
                ? style.VC
                : props.data.type === "data_center"
                ? style.DC
                : props.data.type === "location"
                ? style.Location
                : style.User
            }
          >
            {props.data.type === "ZR"
              ? "VC"
              : props.data.type === "CRC"
              ? "VC"
              : props.data.type === "data_center"
              ? "DC"
              : props.data.type === "location"
              ? "Location"
              : "User"}
          </label>

          {props.data.type === "data_center" ||
          props.data.type === "location" ? (
            ""
          ) : (
            <div>
              <label>|</label>
              <label>{props.data.displayNm}</label>
            </div>
          )}
        </div>
        <div
          className={style.close + " cursor"}
          onClick={() => props.close(props.data.id, props.data.type)}
        />
      </div>

      {props.data.type === "data_center" || props.data.type === "location" ? (
        <div className={style.card2}>
          <label className={style.dcDetail} title={props.data.displayNm}>
            {props.data.displayNm}
          </label>
        </div>
      ) : (
        <div>
          <div className={style.card2}>
            <label title={props.data.detailType}>{props.data.detailType}</label>
          </div>

          <div className={style.card3} />
          <div className={style.card4}>
            <font>Email</font>
            <font>{props.data.email}</font>
          </div>
          <div className={style.card5}>
            <font>Meeting Number</font>
            <font>{props.data.meetingNumber}</font>
          </div>
          <div className={style.card6}>
            <div
              className="cursor"
              onClick={() =>
                props.data.counts > 0
                  ? window.open(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/meetingdetail/live/?id=" +
                        encodeURIComponent(props.data.meetingId),
                      "",
                      "width=1920px, height=900px, scrollbars=yes, resizable=no"
                    )
                  : window.open(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/meetingdetail/past/?id=" +
                        encodeURIComponent(props.data.meetingId),
                      "",
                      "width=1920px, height=900px, scrollbars=yes, resizable=no"
                    )
              }
            >
              Meeting Details
            </div>
            <div
              className="cursor"
              onClick={() =>
                props.data.counts > 0
                  ? window.open(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/participantdetail/" +
                        "live/" +
                        props.data.participantId +
                        "/?id=" +
                        encodeURIComponent(props.data.meetingId),
                      "",
                      "width=1920px, height=900px, scrollbars=yes, resizable=no"
                    )
                  : window.open(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/participantdetail/" +
                        "past/" +
                        props.data.participantId +
                        "/?id=" +
                        encodeURIComponent(props.data.meetingId),
                      "",
                      "width=1920px, height=900px, scrollbars=yes, resizable=no"
                    )
              }
            >
              Network
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Issuecard;
