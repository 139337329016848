import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../../css/meetings.module.css";
import Linechart from "../../components/chart/linechart";
import axios from "axios";
import Loading from "../../components/common/loading";
import { useDispatch } from "react-redux";
import { openAlert } from "../../redux/slices/alertSlice";

function Participantdetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [type, settype] = useState("");
  const [loading, setloading] = useState(false);

  const urlparam = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (location.pathname.split("/")[4] === "live") {
      settype(true);
      getParticipantInfo();
    } else if (location.pathname.split("/")[4] === "past") {
      getParticipantInfo();
      settype(false);
    } else {
      settype(true);
    }
  }, [location]);

  const summary_card = (img, title, contents, line) => {
    return (
      <div className={style.summary_card2}>
        <div className={line ? style.dash : ""} />
        <div className={style.img}>
          <img
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL + "img/detail/" + img + ".png"
            }
          />
        </div>
        <div className={style.text}>
          <font>{title}</font>
          <font title={contents}>{contents}</font>
        </div>
      </div>
    );
  };

  const tableinfo = () => {
    if (qssInfo.length > 0) {
      if (detailtype === "Audio") {
        return (
          <tbody>
            <tr>
              <td>Bitrate</td>
              <td>
                {qssInfo[qssInfo.length - 1].bitrateInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].bitrateInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].bitrateOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].bitrateOutput}
              </td>
            </tr>
            <tr>
              <td>Latency</td>
              <td>
                {qssInfo[qssInfo.length - 1].latencyInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].latencyInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].latencyOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].latencyOutput}
              </td>
            </tr>
            <tr>
              <td>Jitter</td>
              <td>
                {qssInfo[qssInfo.length - 1].jitterInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].jitterInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].jitterOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].jitterOutput}
              </td>
            </tr>
            <tr>
              <td>Packet Loss-Avg(Max)</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossInput}</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossOutput}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Max)</td>
              <td colSpan={2}>{qssInfo[qssInfo.length - 1].maxRssi}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Min)</td>

              <td colSpan={2}>{qssInfo[qssInfo.length - 1].minRssi}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Avg)</td>

              <td colSpan={2}>{qssInfo[qssInfo.length - 1].avgRssi}</td>
            </tr>
          </tbody>
        );
      } else if (detailtype === "CPU") {
        return (
          <tr>
            <td>{qssInfo[qssInfo.length - 1].zoomMinCpuUsage}</td>
            <td>{qssInfo[qssInfo.length - 1].zoomMaxCpuUsage}</td>
            <td>{qssInfo[qssInfo.length - 1].zoomAvgCpuUsage}</td>
            <td>{qssInfo[qssInfo.length - 1].systemMaxCpuUsage}</td>
          </tr>
        );
      } else {
        return (
          <tbody>
            <tr>
              <td>Bitrate</td>
              <td>
                {qssInfo[qssInfo.length - 1].bitrateInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].bitrateInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].bitrateOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].bitrateOutput}
              </td>
            </tr>
            <tr>
              <td>Latency</td>
              <td>
                {qssInfo[qssInfo.length - 1].latencyInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].latencyInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].latencyOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].latencyOutput}
              </td>
            </tr>
            <tr>
              <td>Jitter</td>
              <td>
                {qssInfo[qssInfo.length - 1].jitterInput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].jitterInput}
              </td>
              <td>
                {qssInfo[qssInfo.length - 1].jitterOutput === null
                  ? "-"
                  : qssInfo[qssInfo.length - 1].jitterOutput}
              </td>
            </tr>
            <tr>
              <td>Packet Loss-Avg(Max)</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossInput}</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossOutput}</td>
            </tr>

            <tr>
              <td>Resolution</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossInput}</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossOutput}</td>
            </tr>
            <tr>
              <td>Frame</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossInput}</td>
              <td>{qssInfo[qssInfo.length - 1].maxLossOutput}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Max)</td>
              <td colSpan={2}>{qssInfo[qssInfo.length - 1].maxRssi}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Min)</td>

              <td colSpan={2}>{qssInfo[qssInfo.length - 1].minRssi}</td>
            </tr>
            <tr>
              <td>WIFI RSSI (Avg)</td>

              <td colSpan={2}>{qssInfo[qssInfo.length - 1].avgRssi}</td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const [userInfo, setUserInfo] = useState({
    meetingNumber: "",
    meetingId: "",
    nickName: "",
    signalingIpAddress: "",
    StartTime: "",
    EndTime: "",
    LeaveTime: "",
    duration: "",
    location: "",
    dataCenter: "",
    device: "",
    networkType: "",
    speaker: "",
    camera: "",
    microphone: "",
    leaveReason: "",
  });

  const getParticipantInfo = useCallback(() => {
    let config = {
      params: {
        selectId: urlparam,
        selectUserId: location.pathname.split("/")[5],
      },
    };
    let url = "";
    if (location.pathname.split("/")[4] === "live") {
      url = "/user/live/meetings/participant/detail";
      axios.get(url, config).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            setUserInfo(res.data.data);
          } else if (res.data.code === 201) {
            dispatch(
              openAlert({
                title: "Notice",
                content: res.data.message,
                submitEventHandler: () => navigate(-1),
              })
            );
          }
        }
      });
    } else {
      url = "/user/past/meetings/participant/detail";
      axios.get(url, config).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            setUserInfo(res.data.data);
          } else if (res.data.code === 201) {
            dispatch(
              openAlert({
                title: "Notice",
                content: res.data.message,
                submitEventHandler: () => navigate(-1),
              })
            );
          }
        }
      });
    }
  }, [location]);

  const [detailtype, setdetailtype] = useState("Audio");
  const [qssInfo, setQssInfo] = useState({});
  const getQssData = useCallback(() => {
    let config = {
      params: {
        selectId: urlparam,
        selectUserId: location.pathname.split("/")[5],
      },
    };
    setloading(true);
    let url = "";
    if (location.pathname.split("/")[4] === "live") {
      url = "/user/live/meetings/participant/qss/" + detailtype;
      axios
        .get(url, config)
        .then((res) => {
          if (res.status === 200) {
            setQssInfo(res.data.data);
            setloading(false);
          }
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      url = "/user/past/meetings/participant/qss/" + detailtype;
      axios
        .get(url, config)
        .then((res) => {
          if (res.status === 200) {
            setQssInfo(res.data.data);
            setloading(false);
          }
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [location, detailtype]);

  useEffect(() => {
    getQssData();
  }, [getQssData, detailtype]);

  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };

    const data = {
      selectId: urlparam,
      selectUserId: location.pathname.split("/")[5],
    };

    let url = "";
    if (location.pathname.split("/")[4] === "live") {
      url = "/user/live/meetings/participant/qss/excel";
    } else {
      url = "/user/past/meetings/participant/qss/excel";
    }
    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "QssDetail(" +
          userInfo.meetingNumber +
          "-" +
          userInfo.participantUserId +
          ").xlsx"
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={style.meetings_wrapper}>
      <Loading loading={loading} />
      <div className={style.select_type}>
        <div
          className={type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/live")
          }
        >
          LIVE
        </div>
        <div
          className={!type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/past")
          }
        >
          PAST
        </div>
      </div>
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          height: "calc(100% - 71px)",
        }}
      >
        <div className={style.participantdetail_body}>
          <div className={style.submenu}>
            <label>
              {" "}
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/detail/detail_icon.png"
                }
              />
            </label>
            <label
              className="cursor"
              onClick={() =>
                type
                  ? navigate(
                      process.env.REACT_APP_CLIENT_URL + "app/meetings/live"
                    )
                  : navigate(
                      process.env.REACT_APP_CLIENT_URL + "app/meetings/past"
                    )
              }
            >
              {type ? "Live Meetings" : "Past Meetings"}
            </label>
            <font> {">"}</font>
            <label
              className="cursor"
              onClick={() =>
                navigate(
                  process.env.REACT_APP_CLIENT_URL +
                    "app/meetings/meetingdetail/" +
                    (location.pathname.split("/")[4] === "past"
                      ? "past/?id="
                      : "live/?id=") +
                    encodeURIComponent(userInfo.meetingId)
                )
              }
            >
              {userInfo.meetingNumber}
            </label>
            <font> {">"}</font>
            <label>{userInfo.displayNm}</label>
          </div>
          <div className={style.meetingdetail_info}>
            <div className={style.meeting_summary}>
              <font>Participant</font>
              <div className={style.meeting_summary_info}>
                <section>
                  {summary_card(
                    "detail_id",
                    "Nick Name",
                    userInfo.displayNm,
                    false
                  )}
                  {summary_card("detail_mail", "Email", userInfo.email, true)}
                  {summary_card(
                    "detail_ip",
                    "IP Address",
                    userInfo.signalingIpAddress,
                    true
                  )}
                  {summary_card(
                    "detail_join",
                    "Join Time",
                    userInfo.startTime,
                    true
                  )}
                  {summary_card(
                    "detail_leave",
                    "Leave Time",
                    userInfo.endTime,
                    true
                  )}
                  {summary_card(
                    "detail_duration",
                    "Duration",
                    userInfo.duration,
                    true
                  )}
                </section>
                <section>
                  <table className={style.meeting_summary_table}>
                    <colgroup>
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="13%" />
                      <col width="13%" />
                      <col width="10%" />
                      <col width="13%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Data Center</th>
                        <th>Device</th>
                        <th>Network Type</th>
                        <th>Connection Type</th>
                        <th>Speaker</th>
                        <th>Camera</th>
                        <th>Microphone</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userInfo.location}</td>
                        <td>{userInfo.dataCenter}</td>
                        <td>{userInfo.device}</td>
                        <td>{userInfo.networkType}</td>
                        <td>{userInfo.connectionType}</td>
                        <td>{userInfo.speaker}</td>
                        <td>{userInfo.camera}</td>
                        <td>{userInfo.microphone}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section title={userInfo.leaveReason}>
                  Leave Reason: {userInfo.leaveReason}
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className={style.participant_detail}>
          <div className={style.detail_body}>
            <div className={style.detail_header}>
              <div className={style.detail_menu}>
                <div
                  className={detailtype === "Audio" ? style.detail_focus : ""}
                  onClick={() => setdetailtype("Audio")}
                >
                  Audio
                </div>
                <div
                  className={detailtype === "Video" ? style.detail_focus : ""}
                  onClick={() => setdetailtype("Video")}
                >
                  Video
                </div>
                <div
                  className={detailtype === "Screen" ? style.detail_focus : ""}
                  onClick={() => setdetailtype("Screen")}
                >
                  Screen Sharing
                </div>
                <div
                  className={detailtype === "CPU" ? style.detail_focus : ""}
                  onClick={() => setdetailtype("CPU")}
                >
                  CPU Usage
                </div>
              </div>
              <div
                onClick={() => excelDownload()}
                className={style.detail_csv + " cursor "}
              >
                Export details to Excel
              </div>
            </div>

            {qssInfo.length > 0 ? (
              <div className={style.detail_info}>
                <div className={style.detail_table}>
                  <table>
                    {detailtype === "CPU" ? (
                      <colgroup>
                        <col width="25%" />
                        <col width="25%" />
                        <col width="25%" />
                        <col width="25%" />
                      </colgroup>
                    ) : (
                      <colgroup>
                        <col width="30%" />
                        <col width="35%" />
                        <col width="35%" />
                      </colgroup>
                    )}
                    <thead>
                      {detailtype === "Audio" ? (
                        <tr>
                          <td>
                            {"Audio(" +
                              qssInfo[qssInfo.length - 1].dateTime +
                              ")"}
                          </td>
                          <td>Receiving</td>
                          <td>Sending</td>
                        </tr>
                      ) : detailtype === "Video" ? (
                        <tr>
                          <td>
                            {"Video(" +
                              qssInfo[qssInfo.length - 1].dateTime +
                              ")"}
                          </td>
                          <td>Receiving</td>
                          <td>Sending</td>
                        </tr>
                      ) : detailtype === "Screen" ? (
                        <tr>
                          <td>
                            {"Screen(" +
                              qssInfo[qssInfo.length - 1].dateTime +
                              ")"}
                          </td>
                          <td>Receiving</td>
                          <td>Sending</td>
                        </tr>
                      ) : detailtype === "CPU" ? (
                        <tr>
                          <td>
                            {"Zoom Min CPU Usage(" +
                              qssInfo[qssInfo.length - 1].dateTime +
                              ")"}
                          </td>
                          <td>Zoom Max CPU Usage</td>
                          <td>Zoom Avg CPU Usage</td>
                          <td>System Max CPU Usage</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </thead>
                    {tableinfo()}
                  </table>
                </div>

                {detailtype !== "CPU" ? (
                  <div className={style.detail_graphs}>
                    <Linechart
                      data={qssInfo}
                      title="Bitrate  [Receiving]"
                      subtitle="Bitrate"
                      subtitle_focus=""
                      value="bitrateInput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Bitrate  [Sending]"
                      subtitle="Bitrate"
                      subtitle_focus=""
                      value="bitrateOutput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Latency  [Receiving]"
                      subtitle="Latency"
                      subtitle_focus=""
                      value="latencyInput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Latency  [Sending]"
                      subtitle="Latency"
                      subtitle_focus=""
                      value="latencyOutput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Jitter  [Receiving]"
                      subtitle="Jitter"
                      subtitle_focus=""
                      value="jitterInput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Jitter  [Sending]"
                      subtitle="Jitter"
                      subtitle_focus=""
                      value="jitterOutput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Packet Loss-Avg  [Receiving]"
                      subtitle="Packet Loss-Avg"
                      subtitle_focus="(Max)"
                      value="maxLossInput"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Re Loss-Avg  [Sending]"
                      subtitle="Packet Loss-Avg"
                      subtitle_focus="(Max)"
                      value="maxLossOutput"
                    />

                    <Linechart
                      data={qssInfo}
                      title="WI-FI RSSI"
                      subtitle="WI-FI RSSI"
                      subtitle_focus="(Max)"
                      value="maxRssi"
                    />

                    <Linechart
                      data={qssInfo}
                      title="WI-FI RSSI"
                      subtitle="WI-FI RSSI"
                      subtitle_focus="(Min)"
                      value="minRssi"
                    />

                    <Linechart
                      data={qssInfo}
                      title="WI-FI RSSI"
                      subtitle="WI-FI RSSI"
                      subtitle_focus="(Avg)"
                      value="avgRssi"
                    />
                  </div>
                ) : (
                  <div className={style.detail_graphs}>
                    <Linechart
                      data={qssInfo}
                      title="Zoom CPU Usage"
                      subtitle="Zoom CPU Usage"
                      subtitle_focus="(Min)"
                      value="zoomMinCpuUsage"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Zoom CPU Usage"
                      subtitle="Zoom CPU Usage"
                      subtitle_focus="(Max)"
                      value="zoomAvgCpuUsage"
                    />
                    <Linechart
                      data={qssInfo}
                      title="Zoom CPU Usage"
                      subtitle="Zoom CPU Usage"
                      subtitle_focus="(Avg)"
                      value="zoomMaxCpuUsage"
                    />
                    <Linechart
                      data={qssInfo}
                      title="System CPU Usage"
                      subtitle="System CPU Usage"
                      subtitle_focus="(Max)"
                      value="systemMaxCpuUsage"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={style.detail_info} style={{ height: "100%" }}>
                {" "}
                Qss 데이터가 존재하지 않습니다.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Participantdetail;
