// @ts-nocheck
import { message } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import AdminSelectbox from "../../components/admin/adminSelectbox";
import MonthlyReportTable from "../../components/table/monthlyReportTable";
import style from "../../css/adminComp.module.css";

const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const getWeekBeforeToday = () => {
  const today = new Date();
  const weekBefore = new Date();
  weekBefore.setDate(today.getDate() - 7);

  return weekBefore;
};

function MonthlyReport() {
  const [init, setInit] = useState(true);
  const [searchFilter, setSearchFilter] = useState({
    type: "",
  });
  const [reportList, setReportList] = useState([]);
  const [searchtypelist, setSearchtypeList] = useState([
    { key: "", label: "All Group" },
  ]);
  // const searchtypelist = [
  //   { key: "", label: "All Group" },
  //   { key: "name", label: "Name" },
  // ];

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();

  const getReportList = useCallback(() => {
    let url = "/admin/monthlyreport";
    if (searchFilter.type !== "") url += `/${searchFilter.type}`;

    axios
      .get(url)
      .then((res) => {
        setReportList(res.data.data);
        // set filter option list
        if (init) {
          setSearchtypeList([
            { key: "", label: "All Group" },
            ...[...new Set(res.data.data.map((item) => item.deptNm))].map(
              (group) => ({ key: group, label: group })
            ),
          ]);
        }
      })
      .catch((err) => alert("failed"))
      .finally(() => {});
  }, [searchFilter]);

  useEffect(() => {
    if (init) {
      getReportList();
      setInit(false);
    }
  }, [getReportList, init]);

  useEffect(() => {
    getReportList();
  }, [getReportList, searchFilter.type]);

  return (
    <div className={style.wrapper}>
      {contextHolder}
      <section className={style.search_wrapper}>
        <div style={{ display: "flex" }}>
          {/* <div className={style.search_datebox}>
            <Datepicker
              value={searchFilter.start}
              setValue={(value) =>
                // setSearchFilter({ ...searchFilter, start: value })
                dateHandler("S", value)
              }
            />
            <font> - </font>
            <Datepicker
              value={searchFilter.end}
              setValue={(value) =>
                // setSearchFilter({ ...searchFilter, end: value })
                dateHandler("E", value)
              }
            />
          </div> */}
          <AdminSelectbox
            width={134}
            selectlist={searchtypelist}
            value={searchFilter.type}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, type: e.target.value })
            }
          />
          {/* <Inputtext
            value={searchFilter.text}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, text: e.target.value })
            }
            enter={getLogList}
          /> */}
          {/* <Searchbtn onclickHandler={getLogList} /> */}
        </div>
      </section>
      <section>
        {/* TABLE */}
        <MonthlyReportTable dataList={reportList} />
      </section>
    </div>
  );
}

export default MonthlyReport;
