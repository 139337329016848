import React from "react";
import SignInWidget from "../common/SignInWidget";
import "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
    axios
      .post("/admin/login", tokens)
      .then((res) => {})
      .catch((err) => console.log("login log failed"));
    navigate("/app/monitoring/monitoring");
  };
  const onError = (err) => {
    console.log("[Okta Error Log] ", err);
  };
  return <SignInWidget onSuccess={onSuccess} onError={onError} />;
}

export default Login;
