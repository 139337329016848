import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import style from "../../css/search.module.css";
function PopupSelectbox(props) {
  const selectlist = props.selectlist;
  return (
    <div className={style.selectbox} style={{ width: props.width }}>
      <FormControl sx={{ minWidth: props.width }} size="small">
        <Select
          defaultValue={props.value}
          value={props.value}
          onChange={props.setValue}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ fontSize: 14, height: 35 }}
        >
          {selectlist.length > 0
            ? selectlist.map((value, index) => (
                <MenuItem value={value.key} key={index} sx={{ fontSize: 14 }}>
                  {value.label}
                </MenuItem>
              ))
            : ""}
        </Select>
      </FormControl>
    </div>
  );
}

export default PopupSelectbox;
