import React, { useEffect, useState, useRef } from "react";
import style from "../../css/table.module.css";
import { useLocation, useNavigate } from "react-router-dom";
function Pastmeetingtable(props) {
  const header = [
    { title: "Meeting ID" },
    { title: "Start Time" },
    { title: "End Time" },
    { title: "Duration" },
    { title: "Participants" },
    { title: "Host Email" },
    { title: "Host Nick Name" },
  ];
  const navigate = useNavigate();

  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.meetingpast_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {props.list.map((table, index) => (
              <div
                className={style.meetingpast_tr}
                key={index}
                data-index={index}
              >
                <div
                  onClick={() =>
                    navigate(
                      process.env.REACT_APP_CLIENT_URL +
                        "app/meetings/meetingdetail/past/?id=" +
                        encodeURIComponent(table.meetingId)
                    )
                  }
                  className="cursor"
                >
                  {table.meetingNumber}{" "}
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL + "img/common/link.png"
                    }
                  />
                </div>
                <div>{table.startTime}</div>
                <div>{table.endTime}</div>
                <div>{table.duration}</div>
                <div>{table.participants}</div>
                <div>{table.hostMail}</div>
                <div>{table.hostNm}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pastmeetingtable;
