import React from "react";
import style from "../../css/search.module.css";
function Inputtext(props) {
  const enterHandler = (e) => {
    if (e.key === "Enter") {
      props.enter();
    }
  };
  return (
    <input
      type="text"
      className={style.inputtext}
      placeholder="Search"
      value={props.value}
      onChange={props.setValue}
      onKeyUp={(e) => enterHandler(e)}
    />
  );
}

export default Inputtext;
