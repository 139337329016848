import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../../css/meetings.module.css";
import Inputtext from "../../components/search/inputtext";
import Searchbtn from "../../components/search/searchbtn";
import Excelbtn from "../../components/search/excelbtn";
import Selectbox from "../../components/search/selectbox";
import Meetingdetailtable from "../../components/table/meetingdetailtable";
import axios from "axios";
import Datetimepicker from "../../components/search/datetimepicker";
import { useDispatch } from "react-redux";
import { openAlert } from "../../redux/slices/alertSlice";
function Meetingdetail() {
  const searchtypelist = [
    { key: "nickName", label: "Nick Name" },
    { key: "email", label: "Email" },
    { key: "location", label: "Location" },
    { key: "dataCenter", label: "Data Center" },
  ];

  const [run, setrun] = useState(true);
  const [dateRefresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const urlparam = new URLSearchParams(location.search).get("id");
  const [type, settype] = useState(true);

  useEffect(() => {
    if (location.pathname.split("/")[4] === "live") {
      settype(true);
      getCardInfo("live", urlparam);
    } else if (location.pathname.split("/")[4] === "past") {
      settype(false);
      getCardInfo("past", urlparam);
    } else {
      settype(true);
    }
  }, [location]);
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "nickName",
    searchOption2: "nickName",
    startTime: null,
    endTime: null,
  });
  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "",
    searchOption2: "",
    startTime: null,
    endTime: null,
  });

  const search = () => {
    setSearchInfo(input);

    setrun(true);
  };

  const [participantList, setParticipantList] = useState([]);
  const getissueList = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        keyWord2: searchInfo.keyword2,
        searchOption1: searchInfo.searchOption1,
        searchOption2: searchInfo.searchOption2,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
        selectId: urlparam,
      },
    };

    let url = "";
    if (location.pathname.split("/")[4] === "live") {
      url = "/user/live/meetings/participants";
    } else {
      url = "/user/past/meetings/participants";
    }
    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        setParticipantList(res.data.data);
        setrun(false);
        setRefresh(false);
      }
    });
  }, [searchInfo, type, location]);

  useEffect(() => {
    if (run) {
      getissueList();
    }
  }, [getissueList, run]);

  const [cardInfo, setCardInfo] = useState({
    meetingId: "",
    startTime: "",
    participants: "",
    hostEmail: "",
    HostNickName: "",
  });

  const getCardInfo = useCallback((type, id) => {
    let config = {
      params: {
        selectId: urlparam,
      },
    };
    let url = "user/" + type + "/meetings/detail";

    axios.get(url, config).then((res) => {
      if (res.status === 200) {
        if (res.data.code === 0) {
          setCardInfo(res.data.data);
        } else if (res.data.code === 201) {
          dispatch(
            openAlert({
              title: "Notice",
              content: res.data.message,
              submitEventHandler: () => navigate(-1),
            })
          );
        }
      }
    });
  }, []);

  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };

    const data = {
      keyWord1: searchInfo.keyword1,
      keyWord2: searchInfo.keyword2,
      searchOption1: searchInfo.searchOption1,
      searchOption2: searchInfo.searchOption2,
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      selectId: urlparam,
    };

    let url = "";
    if (type) {
      url = "/user/live/meetings/meetingdetail/excel";
    } else {
      url = "/user/past/meetings/meetingdetail/excel";
    }
    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (type) {
        link.setAttribute(
          "download",
          "LiveMeetingDetail(" + cardInfo.meetingNumber + ").xlsx"
        );
      } else {
        link.setAttribute(
          "download",
          "PastMeetingDetail(" + cardInfo.meetingNumber + ").xlsx"
        );
      }

      document.body.appendChild(link);
      link.click();
    });
  };

  const summary_card = (img, title, contents, line) => {
    return (
      <div className={style.summary_card}>
        <div className={line ? style.dash : ""} />
        <div className={style.img}>
          <img
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL + "img/detail/" + img + ".png"
            }
          />
        </div>
        <div className={style.text}>
          <font>{title}</font>
          <font title={contents}>{contents}</font>
        </div>
      </div>
    );
  };

  return (
    <div className={style.meetings_wrapper}>
      <div className={style.select_type}>
        <div
          className={type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/live")
          }
        >
          LIVE
        </div>
        <div
          className={!type ? style.active + " cursor" : "cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "app/meetings/past")
          }
        >
          PAST
        </div>
      </div>
      <div className={style.meetingdetail_body}>
        <div className={style.submenu}>
          <label>
            <img
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/detail/detail_icon.png"
              }
            />
          </label>
          <label
            className="cursor"
            onClick={() =>
              type
                ? navigate("/app/meetings/live")
                : navigate("/app/meetings/past")
            }
          >
            {type ? "Live Meetings" : "Past Meetings"}
          </label>
          <font> {">"}</font>
          <label>Meeting Details</label>
        </div>
        <div className={style.meetingdetail_info}>
          <div className={style.summary}>
            <font>Meeting</font>
            <div className={style.summary_body}>
              {summary_card(
                "detail_id",
                "Meeting ID",
                cardInfo.meetingNumber,
                false
              )}
              {summary_card(
                "detail_time",
                "Start Time",
                cardInfo.startTime,
                true
              )}
              {summary_card(
                "detail_counts",
                "Participants",
                cardInfo.participants,
                true
              )}
              {summary_card(
                "detail_mail",
                "Host Email",
                cardInfo.hostMail,
                true
              )}
              {summary_card(
                "detail_name",
                "Host Nick Name",
                cardInfo.hostNm,
                true
              )}
            </div>
          </div>
          <div className={style.tableheader}>
            <span>Participant</span>
            <div className={style.search}>
              <Selectbox
                width={134}
                selectlist={searchtypelist}
                placeHolder=""
                value={input.searchOption1}
                onChange={(val) => setInput({ ...input, searchOption1: val })}
              />
              <Inputtext
                value={input.keyword1}
                onChange={(value) => setInput({ ...input, keyword1: value })}
              />
              <span>and</span>
              <Selectbox
                width={134}
                selectlist={searchtypelist}
                placeHolder=""
                value={input.searchOption2}
                onChange={(val) => setInput({ ...input, searchOption2: val })}
              />
              <Inputtext
                value={input.keyword2}
                onChange={(value) => setInput({ ...input, keyword2: value })}
              />
              <Searchbtn search={() => search()} />
              <div style={{ width: "25px" }} />
              <div onClick={() => excelDownload()}>
                <Excelbtn />
              </div>
            </div>
          </div>
          <div className={style.table}>
            <Meetingdetailtable list={participantList} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meetingdetail;
