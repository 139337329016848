import { ConfigProvider, Spin } from "antd";
import React from "react";

function WhiteSpin() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#fff",
        },
      }}
    >
      <Spin />
    </ConfigProvider>
  );
}

export default WhiteSpin;
