import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
function Datepicker(props) {
  const setValue = (value) => {
    let date = dayjs(value).format("YYYY-MM-DD");
    props.onChange(date);
  };

  return (
    <div style={{ outline: "none", border: "none" }} className="datepicker">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        //  sx={{ width: 150, height: 35, fontSize: 13 }}
      >
        <DatePicker
          sx={{ width: 118, height: 35, fontSize: 13 }}
          slotProps={{ textField: { size: "small", fontSize: 13 } }}
          format="YYYY-MM-DD"
          size="small"
          value={dayjs(props.value)}
          onChange={(newValue) => setValue(newValue)}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Datepicker;
