import React from "react";
import style from "../../css/adminComp.module.css";

function SaveBtn({ onclickEvent }) {
  return (
    <button className={`${style.btn} ${style.save}`} onClick={onclickEvent}>
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/save_icon.png"}
      />
      <div>Save</div>
    </button>
  );
}

export default SaveBtn;
