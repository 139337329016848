// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import style from "../../css/admin/loginLogTable.module.css";
import { Spin } from "antd";
function LogTable({ dataList, isLoading }) {
  const header = [
    { title: "Email" },
    { title: "Name" },
    { title: "Access Time" },
    // { title: "Last Access Time" },
  ];

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [dataList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;
  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.logtable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {isLoading ? (
              <div className="resultBox">
                <Spin />
              </div>
            ) : (
              dataList.length === 0 && (
                <div className="resultBox">No results found</div>
              )
            )}
            {!isLoading &&
              dataList.map((data, index) => (
                <div
                  className={style.logtable_tr}
                  key={index}
                  data-index={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                >
                  <div title={data.email}>{data.email}</div>
                  <div title={data.displayNm}>{data.displayNm}</div>
                  <div title={data.createdDt}>{data.createdDt}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {dataList.length}
      </div>
    </div>
  );
}

export default LogTable;
