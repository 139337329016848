import React from "react";
import style from "../../css/adminComp.module.css";

function CloseBtn({ onclickEvent }) {
  return (
    <div className={style.close} onClick={onclickEvent}>
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/close_x.png"}
      />
    </div>
  );
}

export default CloseBtn;
