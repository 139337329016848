// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import style from "../../css/admin/monthlyReportTable.module.css";
function MonthlyReportTable({ dataList }) {
  const header = [
    { title: "Year" },
    { title: "Month" },
    { title: "Group" },
    { title: "Total Meeting Time (minutes)" },
    { title: "Issues (per minutes)" },
    { title: "Issue Percentage (%)" },
  ];

  /** TABLE CONTROLLER */
  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [dataList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  const getMonth = (month) => {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "-";
    }
  };

  return (
    <div className={style.statusBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.logtable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body}>
            {dataList.map((data, index) => (
              <div
                className={style.logtable_tr}
                key={index}
                data-index={index}
                ref={(el) => (rowRefs.current[index] = el)}
              >
                <div title={data.memberId}>{data.year}</div>
                <div title={getMonth(data.memberNm)}>
                  {getMonth(data.month)}
                </div>
                <div title={data.actionType}>{data.deptNm}</div>
                <div title={data.pageType}>{data.total}</div>
                <div title={data.content}>{data.issue}</div>
                <div title={data.createdDt}>{data.issuePct}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {dataList.length}
      </div>
    </div>
  );
}

export default MonthlyReportTable;
