// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { message, Spin } from "antd";
import SlackSettingPopup from "../../components/popup/slackSettingPopup";
import AdminSelectbox from "../../components/admin/adminSelectbox";
import CreateBtn from "../../components/admin/createBtn";
import DeleteBtn from "../../components/admin/deleteBtn";
import Inputtext from "../../components/admin/inputtext";
import Searchbtn from "../../components/admin/searchbtn";
import tableStyle from "../../css/admin/slackSettingTable.module.css";
import style from "../../css/report.module.css";
import axios from "axios";
import OktaAuth from "@okta/okta-auth-js";
import oktaConfig from "../../components/common/oktaConfig";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function SlackSetting() {
  const [init, setInit] = useState(true);
  const [slackList, setSlackList] = useState([]);
  const [searchFilter, setSearchFilter] = useState({ type: "title", text: "" });
  const [groupList, setGroupList] = useState([]);
  const [user, setUser] = useState({ createNm: "", createEmail: "" });
  const [isLoading, setIsLoading] = useState(true);
  const searchtypelist = [
    { key: "title", label: "Title" },
    { key: "url", label: "URL" },
    { key: "type", label: "Type" },
  ];

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = useCallback(
    (type, msg) => {
      messageApi.open({
        type: type,
        content: msg,
      });
    },
    [messageApi]
  );

  /** INIT */
  const getSlackList = useCallback(() => {
    setIsLoading(true);
    oktaAuth.getUser().then((res) => {
      setUser({ createNm: res.name, createEmail: res.email });
    });
    let url = "/admin/slacksetting";
    if (searchFilter.text !== "") {
      url += `/${searchFilter.type}/${searchFilter.text}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setSlackList(res.data.data);
        }
      })
      .catch((err) => alertMsg("error", "Failed loading Slack List"))
      .finally(() => setIsLoading(false));
  }, [alertMsg, searchFilter]);

  const getGroupList = useCallback(() => {
    let url = "/admin/group";
    axios
      .get(url)
      .then((res) => {
        setGroupList(res.data.data);
      })
      .catch((err) => {
        alertMsg("error", "Group List Request Fail");
      });
  }, [alertMsg]);

  useEffect(() => {
    if (init) {
      getSlackList();
      getGroupList();
      setInit(false);
    }
  }, [getGroupList, getSlackList, init]);

  /** TABLE CONTROL */
  const header = [
    { title: "" },
    { title: "Title" },
    { title: "Slack API URL" },
    { title: "Group" },
    { title: "Office Type" },
    { title: "Type" },
  ];

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const visibleRowsRef = useRef(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            visibleRowsRef.current.add(index);
          } else {
            visibleRowsRef.current.delete(index);
          }
        });
        setVisibleRows(new Set(visibleRowsRef.current));
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [groupList]);

  const handleScroll = () => {
    setVisibleRows(new Set(visibleRowsRef.current));
  };

  useEffect(() => {
    const tableBody = document.querySelector(`.${style.table_body}`);
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;
  /** CHECKBOX CONTROL */
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let newCheckedItems;
    if (checked) {
      newCheckedItems = [...checkedItems, Number(value)];
    } else {
      newCheckedItems = checkedItems.filter((item) => item !== Number(value));
    }
    setCheckedItems(newCheckedItems);
  };

  /** MODAL */
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState(null);

  /** TASK */
  const deleteSlack = () => {
    if (checkedItems.length === 0) {
      alertMsg("info", "No items selected");
      return;
    }

    let list = [];
    checkedItems.forEach((item) => {
      list.push(slackList.filter((slack) => slack.id === item)[0]);
    });

    list = list.map((item) => ({
      ...item,
      createEmail: user.createEmail,
    }));

    const config = {
      data: list,
    };

    axios
      .delete("/admin/slacksetting", config)
      .then((res) => {
        if (res.status === 204) {
          setCheckedItems([]);
          alertMsg("success", "Slack Deleted Successfully");
        }
      })
      .catch((err) => alertMsg("error", "Slack Delete Failed"))
      .finally(getSlackList);
  };

  const modifyHandler = (data) => {
    // setModalType("U");
    setModalData(data);
    setIsModalOpen(true);
  };

  const getGroupName = (groupCode) => {
    if (groupCode === null) {
      return "No Group";
    } else {
      return groupList.filter((group) => group.deptCd === groupCode)[0].deptNm;
    }
  };
  const getOfficeName = (officeCd) => {
    return officeCd === "Y" ? "Office" : officeCd === "N" ? "Non-Office" : "-";
  };

  return (
    <div className={style.report_wrapper}>
      {contextHolder}
      <SlackSettingPopup
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        refresh={getSlackList}
        value={modalData}
        list={slackList}
        groupList={groupList}
        user={user}
      />
      <section className={style.report_search}>
        <div style={{ display: "flex" }}>
          <AdminSelectbox
            width={134}
            selectlist={searchtypelist}
            value={searchFilter.type}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, type: e.target.value })
            }
          />
          <Inputtext
            value={searchFilter.text}
            setValue={(e) =>
              setSearchFilter({ ...searchFilter, text: e.target.value })
            }
            enter={getSlackList}
          />
          <Searchbtn onclickHandler={getSlackList} />
        </div>
        <div className="taskBtn">
          <CreateBtn
            onclickEvent={() => {
              // setModalType("C");
              setModalData(null);
              setIsModalOpen(true);
            }}
          />
          <DeleteBtn onclickEvent={deleteSlack} />
        </div>
      </section>
      <section>
        {/* TABLE */}
        <div className={tableStyle.statusBoard}>
          <div className={tableStyle.Statusboard_body}>
            <div className={tableStyle.table}>
              <div className={tableStyle.table_head}>
                <div className={tableStyle.slackSettingtable_tr}>
                  {header.map((table, index) => (
                    <div key={index}> {table.title} </div>
                  ))}
                </div>
              </div>
              <div className={tableStyle.table_body}>
                {isLoading ? (
                  <div className="resultBox">
                    <Spin />
                  </div>
                ) : (
                  slackList.length === 0 && (
                    <div className="resultBox">No results found</div>
                  )
                )}
                {!isLoading &&
                  slackList.map((slack, index) => (
                    <div
                      className={tableStyle.slackSettingtable_tr}
                      key={index}
                      data-index={index}
                      ref={(el) => (rowRefs.current[index] = el)}
                    >
                      <div>
                        <input
                          type="checkbox"
                          value={slack.id}
                          checked={checkedItems.includes(slack.id)}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div
                        title={slack.title}
                        onClick={() => modifyHandler(slack)}
                      >
                        {slack.title}
                      </div>
                      <div
                        title={slack.url}
                        onClick={() => modifyHandler(slack)}
                      >
                        {slack.url}
                      </div>
                      <div
                        title={
                          groupList.length > 0 && getGroupName(slack.deptCd)
                        }
                        onClick={() => modifyHandler(slack)}
                      >
                        {groupList.length > 0 && getGroupName(slack.deptCd)}
                      </div>
                      <div
                        title={getOfficeName(slack.officeYn)}
                        onClick={() => modifyHandler(slack)}
                      >
                        {getOfficeName(slack.officeYn)}
                      </div>
                      <div
                        title={slack.type}
                        onClick={() => modifyHandler(slack)}
                      >
                        {slack.type}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={tableStyle.statusBoard_footer}>
            Status :{" "}
            <font>
              {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
            </font>{" "}
            of {slackList.length}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SlackSetting;
