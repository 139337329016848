const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ORG_URL = process.env.REACT_APP_OKTA_ORG_URL;
const API_PATH = process.env.REACT_APP_OKTA_API_PATH;
const ISSUER = `${ORG_URL}/oauth2/${API_PATH}`;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

const oktaConfig = {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'email', 'profile', 'groups'],
    },
    widget: {
        issuer: ISSUER,
        baseUrl: ORG_URL,
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
        authParams: {
            scopes: ['openid', 'email', 'profile', 'groups']
        },
        logo: "/img/menu/logo.png"
    }
}

export default oktaConfig