import { Modal, Spin, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import table from "../../css/admin/monitoringAccountTable.module.css";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";
import WhiteSpin from "../admin/whiteSpin";

function ZoomAccountPopup({ isOpen, close, registeredList, user }) {
  const [zoomId, setZoomId] = useState("");
  const [zoomList, setZoomList] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);

  /** loading */
  const [isZoomListLoading, setIsZoomListLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);

  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alert = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
    });
  };

  const getZoomList = () => {
    setIsZoomListLoading(true);
    setZoomList(null);
    setSelectedValue("");
    setSelectedAccount(null);

    const url = "/admin/monitoringaccount/user/" + zoomId;

    axios
      .get(url)
      .then((res) => {
        setZoomList(res.data.data);
      })
      .catch((err) => {
        alert("error", "Failed");
      })
      .finally(() => setIsZoomListLoading(false));
  };

  const registerHandler = (data) => {
    const isRegistered =
      registeredList.filter((registered) => registered.email === data.email)
        .length > 0;
    if (isRegistered) {
      alert("info", "Account is already registered");
      return;
    }
    setIsRegisterLoading(true);
    const zData = { ...data, createEmail: user.createEmail };
    axios
      .post("/admin/monitoringaccount", zData)
      .then((res) => {
        if (res.status === 201) {
          alert("success", "Account Registered Successfully");
          closeHandler();
        }
      })
      .catch((err) => {
        alert("error", "Account Register Failed");
      })
      .finally(() => setIsRegisterLoading(false));
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const closeHandler = () => {
    setZoomId("");
    setZoomList(null);
    setSelectedValue("");
    setSelectedAccount(null);
    close();
  };
  /** Popup Open */
  useEffect(() => {
    if (isOpen) {
      setZoomId("");
      setZoomList(null);
      setSelectedValue("");
      setSelectedAccount(null);
    }
  }, [isOpen]);

  /** Handle Checked */
  useEffect(() => {
    if (selectedValue !== "") {
      let tmp = zoomList.filter(
        (zoom) => zoom.participantUserId === selectedValue
      )[0];
      setSelectedAccount(tmp);
    }
  }, [selectedValue, zoomList]);

  return (
    <Modal
      className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={435}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div className={comp.modal_title}>Zoom Account Registration</div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section className={comp.modal_content}>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>ID ( Email )</div>
          <div className={comp.modal_searchInput_area}>
            <input
              type="text"
              className={`${comp.modal_searchInput} ${comp.input_border}`}
              value={zoomId}
              onChange={(e) => setZoomId(e.target.value)}
            />
            <button
              className={`${comp.modal_searchBtn} ${comp.blueBtn}`}
              onClick={getZoomList}
              disabled={zoomId === ""}
            >
              Search
            </button>
          </div>
        </div>
      </section>
      {isZoomListLoading && (
        <div
          style={{
            width: "384px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "15px",
          }}
        >
          <Spin />
        </div>
      )}
      {zoomList === null ? (
        <></>
      ) : zoomList !== null && zoomList.length > 0 ? (
        <section className={table.accList_area}>
          <div className={table.accList_header}>
            <div className={table.accList_col_1}></div>
            <div className={table.accList_col_2}>ID ( Email )</div>
            <div className={table.accList_col_3}>Nick Name</div>
          </div>
          <div className={table.accList_body}>
            {zoomList.map((zoom, idx) => (
              <div className={table.accList_tr} key={idx}>
                <div className={table.accList_col_1}>
                  <input
                    type="radio"
                    id={zoom.participantUserId}
                    name={zoom.participantUserId}
                    value={zoom.participantUserId}
                    checked={selectedValue === zoom.participantUserId}
                    onChange={handleRadioChange}
                  />
                </div>
                <div className={table.accList_col_2}>{zoom.email}</div>
                <div className={table.accList_col_3}>{zoom.displayNm}</div>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <div
          style={{
            width: "384px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "15px",
          }}
        >
          No matching user found
        </div>
      )}
      {selectedAccount !== null && (
        <section className={comp.modal_btnArea}>
          <button
            className={`${comp.modal_btn} ${comp.blueBtn}`}
            onClick={() => registerHandler(selectedAccount)}
          >
            {isRegisterLoading ? <WhiteSpin /> : "Register"}
          </button>
          <button
            className={`${comp.modal_btn} ${comp.whiteBtn}`}
            onClick={closeHandler}
          >
            Cancel
          </button>
        </section>
      )}
    </Modal>
  );
}

export default ZoomAccountPopup;
