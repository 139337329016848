import React from "react";
import style from "../../css/search.module.css";
function Searchbtn({ onclickHandler }) {
  return (
    <div className={style.searchBtn + " cursor"} onClick={onclickHandler}>
      Search
    </div>
  );
}

export default Searchbtn;
