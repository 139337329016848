import React, { useEffect, useState, useRef } from "react";
import style from "../../css/table.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ClassNames } from "@emotion/react";
function Meetingdetailtable(props) {
  const header = [
    { title: "Nick Name" },
    { title: "Email" },
    { title: "IP Address" },
    { title: "Join Time" },
    { title: "Leave Time" },
    { title: "Duration" },
    { title: "Location" },
    { title: "Data Center" },
    { title: "Device" },
    { title: "Network Type" },
    { title: "Connection Type" },
    { title: "Speaker" },
    { title: "Camera" },
    { title: "Microphone" },
    { title: "Leave Reason" },
    { title: "ParticipantUserId" },
  ];

  const [visibleRows, setVisibleRows] = useState(new Set());
  const rowRefs = useRef([]);
  const observer = useRef(null);
  const location = useLocation();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const newVisibleRows = new Set(visibleRows);
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            newVisibleRows.add(index);
          } else {
            newVisibleRows.delete(index);
          }
        });
        setVisibleRows(newVisibleRows);
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    rowRefs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [visibleRows]);

  const visibleIndices = Array.from(visibleRows).sort((a, b) => a - b);
  const minVisibleRow = visibleIndices.length ? visibleIndices[0] : null;
  const maxVisibleRow = visibleIndices.length
    ? visibleIndices[visibleIndices.length - 1]
    : null;

  const navigate = useNavigate();

  return (
    <div className={style.meetingdetailBoard}>
      <div className={style.Statusboard_body}>
        <div className={style.detail_table}>
          <div className={style.table_head}>
            <div className={style.meetingdetailtable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body + " " + style.overflow}>
            {props.list.map((table, index) => (
              <div
                onClick={() =>
                  navigate(
                    process.env.REACT_APP_CLIENT_URL +
                      "app/meetings/participantdetail/" +
                      (location.pathname.split("/")[4] === "past"
                        ? "past/" + table.participantId + "/?id="
                        : "live/" + table.participantId + "/?id=") +
                      encodeURIComponent(table.meetingId)
                  )
                }
                className={style.meetingdetailtable_tr + " cursor"}
                key={index}
                data-index={index}
                ref={(el) => (rowRefs.current[index] = el)}
              >
                <div title={table.displayNm}>{table.displayNm}</div>
                <div title={table.email}>{table.email}</div>
                <div title={table.signalingIpAddress}>
                  {table.signalingIpAddress}
                </div>
                <div title={table.startTime}>{table.startTime}</div>
                <div title={table.endTime}>{table.endTime}</div>
                <div title={table.duration}>{table.duration}</div>
                <div title={table.location}>{table.location}</div>
                <div title={table.dataCenter}>{table.dataCenter}</div>
                <div title={table.device}>{table.device}</div>
                <div title={table.networkType}>{table.networkType}</div>
                <div title={table.connectionType}>{table.connectionType}</div>
                <div title={table.speaker}>{table.speaker}</div>
                <div title={table.camera}>{table.camera}</div>
                <div title={table.microphone}>{table.microphone}</div>
                <div title={table.leaveReason}>{table.leaveReason}</div>
                <div title={table.participantUserId}>
                  {table.participantUserId}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.statusBoard_footer}>
        Status :{" "}
        <font>
          {minVisibleRow + 1} - {maxVisibleRow + 1}{" "}
        </font>{" "}
        of {props.list.length}
      </div>
    </div>
  );
}

export default Meetingdetailtable;
