import React from "react";
import style from "../../css/table.module.css";

function Issuetable(props) {
  const header = [
    { title: "Nick Name" },
    { title: "Email" },
    { title: "Meeting ID" },
    { title: "Meeting Time" },
    { title: "Error Message" },
    { title: "Error Time" },
    { title: "Confirm Name" },
    { title: "Confirm Time" },
  ];

  return (
    <div className={style.statusBoard_long}>
      <div className={style.Statusboard_body}>
        <div className={style.table}>
          <div className={style.table_head}>
            <div className={style.issuetable_tr}>
              {header.map((table, index) => (
                <div key={index}> {table.title} </div>
              ))}
            </div>
          </div>
          <div className={style.table_body + " " + style.table_body_long}>
            {props.tablelist.map((table, index) => (
              <div
                className={style.issuetable_tr}
                key={index}
                data-index={index}
              >
                <div>{table.nickName}</div>
                <div>{table.email}</div>
                <div>{table.meetingNumber}</div>
                <div>
                  {table.startTime} - {table.endTime}
                </div>
                <div title={table.detailType + table.detailValue}>
                  {table.detailType} {table.detailValue}
                </div>
                <div>{table.dateTime}</div>
                <div>{table.confirmNm}</div> <div>{table.confirmDt}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Issuetable;
