import { Modal, Switch, message } from "antd";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import comp from "../../css/adminComp.module.css";
import CloseBtn from "../admin/closeBtn";
import PopupSelectbox from "../admin/popupSelectbox";

function SlackSettingPopup({
  isOpen,
  close,
  refresh,
  value,
  list,
  groupList,
  user,
}) {
  const slackContent = useMemo(
    () => ({
      title: "",
      url: "",
      type: "Info",
      deptCd: null,
      officeYn: "Y",
      msgtypeUseYn: "Y",
      detailsUseYn: "Y",
      whenUseYn: "Y",
      deviceUseYn: "Y",
      nicknameUseYn: "Y",
      emailUseYn: "Y",
      meetingidUseYn: "Y",
      ipUseYn: "Y",
      locationUseYn: "Y",
      zoomdcUseYn: "Y",
    }),
    []
  );
  const [data, setData] = useState(slackContent);
  const closeHandler = () => {
    setData(slackContent);
    refresh();
    close();
  };
  const searchtypelist = [
    { key: "Info", label: "Info" },
    { key: "Error", label: "Error" },
  ];
  const groupSelectList = [
    { key: null, label: "No Group" },
    ...groupList.map((group) => ({
      key: group.deptCd,
      label: group.deptNm,
    })),
  ];
  const officeTypeSelectList = [
    { key: "Y", label: "Office" },
    { key: "N", label: "Non-Office" },
  ];
  /** ALERT */
  const [messageApi, contextHolder] = message.useMessage();
  const alertMsg = (type, msg) => {
    messageApi.open({
      type: type,
      content: msg,
    });
  };
  useEffect(() => {
    if (isOpen) {
      setData(value === null ? slackContent : value);
    }
  }, [isOpen, slackContent, value]);

  const validateInputs = () => {
    for (const key in data) {
      if (data[key] === "") {
        alertMsg("info", "Please fill in all the blanks");
        return false;
      }
    }
    return true;
  };

  const saveHandler = (type, data) => {
    if (validateInputs()) {
      if (true) {
        const slackData = { ...data, createEmail: user.createEmail };
        if (type === "C") {
          axios
            .post("/admin/slacksetting", slackData)
            .then((res) => {
              if (res.status === 201) {
                alertMsg("success", "Slack Registered Successfully");
                closeHandler();
              }
            })
            .catch((err) => alertMsg("error", "Slack Registration Failed"));
        } else if (type === "U") {
          axios
            .put("/admin/slacksetting", slackData)
            .then((res) => {
              if (res.status === 200) {
                alertMsg("success", "Slack Modified Successfully");
                closeHandler();
              }
            })
            .catch((err) => alertMsg("error", "Slack Modification Failed"));
        }
      }
    }
  };

  const ynToBoolean = (data) => {
    return data === "Y" ? true : data === "N" ? false : null;
  };
  const booleanToYn = (booleanValue) => {
    return booleanValue ? "Y" : "N";
  };

  return (
    <Modal
      className="adminPopup"
      open={isOpen}
      footer={null}
      closable={false}
      width={435}
      centered
    >
      {contextHolder}
      <section className={comp.modal_header}>
        <div className={comp.modal_title}>
          Slack {value === null ? "Registration" : "Modify"}
        </div>
        <CloseBtn onclickEvent={closeHandler} />
      </section>
      <section className={comp.modal_content}>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Title</div>
          <input
            type="text"
            className={`${comp.modal_inputBox} ${comp.input_border}`}
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>URL</div>
          <input
            type="text"
            className={`${comp.modal_inputBox} ${comp.input_border}`}
            value={data.url}
            onChange={(e) => setData({ ...data, url: e.target.value })}
          />
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Type</div>
          <div
            style={{
              height: "35px",
              marginTop: "5px",
            }}
          >
            <PopupSelectbox
              width={383}
              selectlist={searchtypelist}
              value={data.type}
              setValue={(e) => setData({ ...data, type: e.target.value })}
            />
          </div>
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Group</div>
          <div
            style={{
              height: "35px",
              marginTop: "5px",
            }}
          >
            <PopupSelectbox
              width={383}
              selectlist={groupSelectList}
              value={data.deptCd}
              setValue={(e) => setData({ ...data, deptCd: e.target.value })}
            />
          </div>
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Office Type</div>
          <div
            style={{
              height: "35px",
              marginTop: "5px",
            }}
          >
            <PopupSelectbox
              width={383}
              selectlist={officeTypeSelectList}
              value={data.officeYn}
              setValue={(e) => setData({ ...data, officeYn: e.target.value })}
            />
          </div>
        </div>
      </section>
      <section className={comp.modal_content}>
        {/* <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Color</div>
          <input
            type="text"
            placeholder="#000000"
            className={comp.modal_inputBox}
            value={data.color}
            onChange={(e) => setData({ ...data, color: e.target.value })}
          />
        </div> */}
        {/* <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>Pretext</div>
          <input
            type="text"
            placeholder="Slack Pretext"
            className={comp.modal_inputBox}
            value={data.pretext}
            onChange={(e) => setData({ ...data, pretext: e.target.value })}
          />
        </div> */}
        {/* <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Type
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.msgtypeUseYn)}
              onChange={(checked) =>
                setData({ ...data, msgtypeUseYn: booleanToYn(checked) })
              }
            />
          </div>
          <input
            type="text"
            placeholder="Type :"
            className={comp.modal_inputBox}
            value={data.msgtype}
            onChange={(e) => setData({ ...data, msgtype: e.target.value })}
          />
        </div> */}
        {/* <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Details
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.detailsUseYn)}
              onChange={(checked) =>
                setData({ ...data, detailsUseYn: booleanToYn(checked) })
              }
            />
          </div>
          <input
            type="text"
            placeholder="Details :"
            className={comp.modal_inputBox}
            value={data.details}
            onChange={(e) => setData({ ...data, details: e.target.value })}
          />
        </div> */}
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            When
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.whenUseYn)}
              onChange={(checked) =>
                setData({ ...data, whenUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="When :"
            className={comp.modal_inputBox}
            value={data.when}
            onChange={(e) => setData({ ...data, when: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Device
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.deviceUseYn)}
              onChange={(checked) =>
                setData({ ...data, deviceUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="Device :"
            className={comp.modal_inputBox}
            value={data.device}
            onChange={(e) => setData({ ...data, device: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            NickName
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.nicknameUseYn)}
              onChange={(checked) =>
                setData({ ...data, nicknameUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="NickName :"
            className={comp.modal_inputBox}
            value={data.nickname}
            onChange={(e) => setData({ ...data, nickname: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Email
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.emailUseYn)}
              onChange={(checked) =>
                setData({ ...data, emailUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="Email :"
            className={comp.modal_inputBox}
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Meeting ID
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.meetingidUseYn)}
              onChange={(checked) =>
                setData({ ...data, meetingidUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="Meeting ID :"
            className={comp.modal_inputBox}
            value={data.meetingid}
            onChange={(e) => setData({ ...data, meetingid: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            IP
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.ipUseYn)}
              onChange={(checked) =>
                setData({ ...data, ipUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="IP :"
            className={comp.modal_inputBox}
            value={data.ip}
            onChange={(e) => setData({ ...data, ip: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Location
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.locationUseYn)}
              onChange={(checked) =>
                setData({ ...data, locationUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="Location :"
            className={comp.modal_inputBox}
            value={data.location}
            onChange={(e) => setData({ ...data, location: e.target.value })}
          /> */}
        </div>
        <div className={comp.modal_inputArea}>
          <div className={comp.modal_inputTitle}>
            Zoom DC
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={ynToBoolean(data.zoomdcUseYn)}
              onChange={(checked) =>
                setData({ ...data, zoomdcUseYn: booleanToYn(checked) })
              }
            />
          </div>
          {/* <input
            type="text"
            placeholder="Zoom DC :"
            className={comp.modal_inputBox}
            value={data.zoomdc}
            onChange={(e) => setData({ ...data, zoomdc: e.target.value })}
          /> */}
        </div>
      </section>
      <section className={comp.modal_btnArea}>
        <button
          className={`${comp.modal_btn} ${comp.blueBtn}`}
          onClick={() => {
            if (value === null) saveHandler("C", data);
            else saveHandler("U", data);
            // closeHandler();
          }}
        >
          {value === null ? "Save" : "Modify"}
        </button>
        <button
          className={`${comp.modal_btn} ${comp.whiteBtn}`}
          onClick={closeHandler}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
}

export default SlackSettingPopup;
