import React from "react";
import style from "../../css/search.module.css";
function Excelbtn() {
  return (
    <div className={style.excelbtn + " cursor"}>
      <img
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/common/excel.png"}
      />
      Excel DN
    </div>
  );
}

export default Excelbtn;
