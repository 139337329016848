import React from "react";
import style from "../../css/adminComp.module.css";

function FileDownloadBtn({ onclickEvent }) {
  return (
    <button
      className={`${style.btn} ${style.fileDownload}`}
      onClick={onclickEvent}
    >
      <img
        className={style.btnImg}
        alt=""
        src={process.env.REACT_APP_CLIENT_URL + "img/icon/download_icon.png"}
      />
      <div>File Download</div>
    </button>
  );
}

export default FileDownloadBtn;
