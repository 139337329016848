import React, { useCallback, useEffect, useState } from "react";
import Selectbox from "../components/search/selectbox";
import Datepicker from "../components/search/datepicker";
import style from "../css/report.module.css";
import Barchart from "../components/chart/barchart";
import Searchbtn from "../components/search/searchbtn";
import Inputtext from "../components/search/inputtext";
import Excelbtn from "../components/search/excelbtn";
import axios from "axios";
import moment from "moment";

function Qualityreport() {
  const typelist = [
    { key: "user", label: "User" },
    { key: "group", label: "Group" },
  ];

  const typelist2 = [
    { key: "date", label: "Date" },
    { key: "weekend", label: "Weekend" },
  ];
  const [groupList, setGroupList] = useState([{ key: "all", label: "All" }]);

  const [run, setrun] = useState(true);
  const [run2, setrun2] = useState(true);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const getWeekBeforeToday = () => {
    const today = new Date();
    const weekBefore = new Date();
    weekBefore.setDate(today.getDate() - 7);

    return weekBefore;
  };

  const getGroupList = useCallback(() => {
    axios.get("/admin/report/getGroupList", "").then((res) => {
      if (res.status === 200) {
        let list = [];
        res.data.data.map((info) =>
          list.push({ key: info.deptCd, label: info.deptNm })
        );
        setGroupList(list);
        setrun2(false);
      }
    });
  }, []);
  useEffect(() => {
    if (run2) {
      getGroupList();
    }
  }, [getGroupList, run2]);

  const [input, setInput] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "user",
    searchOption2: "date",
    startTime: getFormattedDate(getWeekBeforeToday()),
    endTime: getFormattedDate(new Date()),
  });
  const [searchInfo, setSearchInfo] = useState({
    keyword1: "",
    keyword2: "",
    searchOption1: "user",
    searchOption2: "date",
    startTime: getFormattedDate(getWeekBeforeToday()),
    endTime: getFormattedDate(new Date()),
  });

  const [chartInfo, setChartInfo] = useState([
    { date: "0", duration: "0", error: "0" },
  ]);

  const [total, setTotal] = useState({
    duration: 0,
    error: 0,
  });
  const getChartInfo = useCallback(() => {
    let config = {
      params: {
        keyWord1: searchInfo.keyword1,
        keyWord2: searchInfo.keyword2,
        searchOption1: searchInfo.searchOption1,
        searchOption2: searchInfo.searchOption2,
        startTime: searchInfo.startTime,
        endTime: searchInfo.endTime,
      },
    };

    setrun(false);
    axios.get("/admin/report/getQualityList", config).then((res) => {
      if (res.status === 200) {
        setChartInfo(res.data.data);
        let duration = 0;
        let error = 0;

        res.data.data.forEach((item) => {
          duration = duration + item.duration;
          error = error + item.error;
        });

        setTotal({
          duration: duration,
          error: error,
        });
      }
    });
  }, [searchInfo]);
  useEffect(() => {
    if (run) {
      getChartInfo();
    }
  }, [getChartInfo, run]);

  const search = () => {
    setSearchInfo(input);
    setrun(true);
  };

  useEffect(
    () => {
      if (input.searchOption1 === "user") {
        setInput({ ...input, keyword1: "" });
      } else {
        setInput({ ...input, keyword1: groupList[0].key });
      }
    },
    // eslint-disable-next-line
    [input.searchOption1]
  );

  const getLabel = () => {
    for (let i = 0; i < groupList.length; i++) {
      if (groupList[i].key === searchInfo.keyword1) {
        return groupList[i].label;
      }
    }
  };

  const excelDownload = () => {
    const config = {
      responseType: "blob",
    };
    const data = {
      keyWord1: searchInfo.keyword1,
      keyWord2: searchInfo.keyword2,
      searchOption1: searchInfo.searchOption1,
      searchOption2: searchInfo.searchOption2,
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
    };

    let url = "/admin/report/getQualityList/excel";

    axios.post(url, data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      let startTime =
        searchInfo.startTime === null ? "Entire Period" : searchInfo.startTime;
      let endTime =
        searchInfo.endTime === null ? "Entire Period" : searchInfo.endTime;

      link.setAttribute(
        "download",
        "Quality_Report(" + startTime + " ~ " + endTime + ").xlsx"
      );

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={style.report_wrapper}>
      <section className={style.report_search}>
        <div style={{ display: "flex" }}>
          <Selectbox
            width={120}
            selectlist={typelist}
            placeHolder=""
            value={input.searchOption1}
            onChange={(val) => setInput({ ...input, searchOption1: val })}
          />
          {input.searchOption1 === "user" ? (
            <Inputtext
              value={input.keyword1}
              onChange={(value) => setInput({ ...input, keyword1: value })}
            />
          ) : (
            <Selectbox
              width={200}
              selectlist={groupList}
              placeHolder=""
              value={input.keyword1}
              onChange={(val) => setInput({ ...input, keyword1: val })}
            />
          )}

          <Selectbox
            width={120}
            selectlist={typelist2}
            placeHolder=""
            value={input.searchOption2}
            onChange={(val) => setInput({ ...input, searchOption2: val })}
          />
          <div className={style.report_search_datebox}>
            <font>Meeting Date :</font>
            <Datepicker
              value={input.startTime}
              onChange={(value) => setInput({ ...input, startTime: value })}
            />
            <font> - </font>
            <Datepicker
              value={input.endTime}
              onChange={(value) => setInput({ ...input, endTime: value })}
            />
          </div>
          <Searchbtn search={() => search()} />
        </div>
        <div onClick={() => excelDownload()}>
          <Excelbtn />
        </div>
      </section>
      <section>
        <div className={style.quality_body}>
          <div className={style.quality_header}>
            <div className={style.quality_header_contents}>
              <div>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL + "img/report/report.png"
                  }
                />
              </div>
              <div>
                <font>{searchInfo.searchOption1}</font>
                <font>
                  {searchInfo.keyword1 === ""
                    ? "All User"
                    : searchInfo.searchOption1 === "user"
                    ? searchInfo.keyword1
                    : getLabel(groupList)}
                </font>
              </div>
              <div>
                <font></font>
                <font>Issue Percent </font>
                <font>
                  {total.duration === null || total.duration === 0
                    ? 0
                    : ((total.error / total.duration) * 100).toFixed(2)}{" "}
                  %
                </font>
              </div>
              <div>
                {" "}
                <font></font>
                <font>Uptime</font>
                <font>{total.duration} min</font>
              </div>
              <div>
                <font></font>
                <font>Error</font>
                <font>{total.error} min</font>
              </div>
            </div>
          </div>
          <div>
            <Barchart type={searchInfo.searchOption2} data={chartInfo} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Qualityreport;
