import React, { useEffect, useState } from "react";
import { BarChart, barElementClasses } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
function Barchart(props) {
  const [type, settype] = useState("date");
  useEffect(() => {
    settype(props.type);
  }, [props.type]);

  const valueFormatter = (value) => `${value} min`;
  return (
    <div>
      {
        <BarChart
          dataset={props.data}
          xAxis={[
            {
              scaleType: "band",
              dataKey: type,
              tickPlacement: "middle",
            },
          ]}
          series={[
            {
              dataKey: "duration",
              id: "bars",
              label: "Uptime",
              valueFormatter,
              color: "#0073e9",
            },
            {
              dataKey: "error",
              id: "bars2",
              label: "Error",
              valueFormatter,
              color: "#d74343",
            },
          ]}
          grid={{ horizontal: true, vertical: true }}
          height={550}
          tickPlacement="middle"
          tooltip={{ trigger: "item", axisContent: "color : black" }}
          sx={(theme) => ({
            [`.${barElementClasses.root}`]: {
              fill: theme.palette.background.paper,
              strokeWidth: 1,
            },

            [`.MuiBarElement-series-bars`]: {
              stroke: "#0073e9",
              fill: "rgba(0, 115, 233, 0.1)",
            },
            [`.MuiBarElement-series-bars2`]: {
              stroke: "#d74343",
              fill: " rgba(215, 67, 67, 0.1);",
            },

            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: "#555555",
                strokeWidth: 0,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: "#111111",
              },
            },
          })}
        />
      }
    </div>
  );
}

export default Barchart;
