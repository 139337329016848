import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import OktaConfig from "./oktaConfig";
import { OktaSignIn } from "@okta/okta-signin-widget";

function SignInWidget({ onSuccess, onError }) {
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    const widget = new OktaSignIn(OktaConfig.widget);
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);
    return () => widget.remove();
  }, [onError, onSuccess]);

  return <div ref={widgetRef} />;
}

export default SignInWidget;
